import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IErrorAlertOptions, UINotificationService } from 'src/app/services/ui-notification/uinotification.service';

@Component({
  selector: 'app-create-process-dialog',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})

export class ErrorAlertComponent {
  @ViewChild('errorTextArea') textArea!: ElementRef<HTMLElement>;
  title!: string;
  message!: string;
  details!: string | undefined;
  hoverOver: boolean = false;

  constructor(
    private clipboard: Clipboard,
    private uiNotifications: UINotificationService,
    @Inject(MAT_DIALOG_DATA) public data: IErrorAlertOptions,
  ) {
    this.title = data.title;
    this.message = data.message;

    if(data.details) {
      if(typeof(data.details) === 'object') {
        this.details = JSON.stringify(data.details);
      } else {
        this.details = data.details;
      }
    }
  }

  copyErrorMessage() {
    const textToCopy = this.textArea.nativeElement.innerHTML;
    const pending = this.clipboard.beginCopy(textToCopy);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        this.uiNotifications.displaySnackbar({
          message: 'Error Message Copied to Clipboard',
          durationMilliseconds: 2300
        });
        pending.destroy();
      }
    };
    attempt();
  }
}
