import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialImportsModule } from 'src/app/material-imports.module';
import { SnackbarDefaultContentContainerComponent } from './snackbar-default-content-container.component';

@NgModule({
  declarations: [ SnackbarDefaultContentContainerComponent ],
  imports: [
    CommonModule,
    MaterialImportsModule
  ],
  exports: [ SnackbarDefaultContentContainerComponent ]
})
export class SnackbarDefaultContentContainerModule {

}
