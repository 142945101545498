import { Component, Inject, Injectable, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { IThingie } from 'src/app/model/thingie/thingie';
import { ITeam } from 'src/app/model/user-management/team';
import { DeviceService } from 'src/app/services/device/device.service';
import { TeamService } from 'src/app/services/team/team.service';
import { ThingieService } from 'src/app/services/thingie/thingie-service.service';
import {
  ConfirmationDialogOptions,
  ConfirmationResults,
  UINotificationService
} from 'src/app/services/ui-notification/uinotification.service';
import { UserService } from 'src/app/services/user/user.service';
import { SubSink } from 'subsink';

@Component({
  templateUrl: './device-detail-dialog.component.html',
  styleUrls: ['./device-detail-dialog.component.scss']
})
export class DeviceDetailDialogComponent implements OnDestroy {
  deviceObj!: IPojoDevice;
  connectedCoordinatorPanID?: number;
  connectedCoordinatorChannel?: number;
  loraPHDOSoftwareVersion?: string;
  private subscriptions = new SubSink();
  deviceActions: string[] = [];
  showMore = false;
  private teamList: ITeam[] = [];
  private thingieList: IThingie[] = [];
  devicesDataList: IPojoDevice[] = [];
  isEditingName = false;
  isEditingPanID = false;
  showBlockedPopout = false;
  connectionState = 'unknown';
  signalStrengthQuality = 'No signal';
  batteryPower = '';
  newNameFormControl = new FormControl('');
  newPanIDFormControl = new FormControl('');
  showMenu = false;
  selectedAction = 'Actions';
  deviceNameMap = new Map([
    ['AquilaLisDrive', 'LIS Drive'],
    ['AquilaLisCoordinator', 'LIS Coordinator'],
    ['AquilaCGQBaseStation', 'CGQ Base Station 8'],
    ['AquilaCGQSensorPlate', 'CGQ Sensor Plate'],
    ['AquilaBioRSensorPlate', 'CGQ BioR Sensor Plate'],
    ['AquilaDotsDO', 'DOTS DO Sensor'],
    ['AquilaDotsPH', 'DOTS pH Sensor'],
    ['AquilaDotsPHDO', 'DOTS pH/DO Sensor']]);
  deviceActionMap = new Map([
    ['START_BLINK', 'Start blink'],
    ['STOP_BLINK', 'Stop blink'],
    ['BLINK_30_S', 'Blink for 30s'],
    ['SOFTRESET', 'Soft reset'],
    ['HARDRESET', 'Hard reset'],
    ['AUTORESET', 'Auto reset'],
    ['BOOT', 'Boot'],
    ['RESET', 'Reset']]);

  isDeviceArchived: boolean = false;
  isAdmin?: boolean;
  constructor(
    private deviceService: DeviceService,
    @Inject(MAT_DIALOG_DATA) private deviceId: string,
    private uiNotifications: UINotificationService,
    private teamService: TeamService,
    private router: Router,
    private thingieService: ThingieService,
    private dialogRef: MatDialogRef<DeviceDetailDialogComponent>,
    private user: UserService,
  ) {
    this.loadDeviceDetail(deviceId);
    this.getDeviceActions(deviceId);
    this.getTeamListAndUserRole();
    this.loadThingieList();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async getTeamListAndUserRole() {
    const _currentUser = await this.user.getCurrentUser();
    this.isAdmin = _currentUser.admin;
    this.teamList = await this.teamService.getTeamList();
  }

  getSignalStrengthQuality() {
    if (this.deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED') {
      let signalStrength = this.deviceObj?.info?.StatusAnswer?.values?.signalStrength as number;
      if (signalStrength > 100) {
        this.signalStrengthQuality = 'Excellent';
      }
      if (signalStrength > 50 && signalStrength <= 100) {
        this.signalStrengthQuality = 'Good';
      }
      if (signalStrength > 10 && signalStrength <= 50) {
        this.signalStrengthQuality = 'Ok';
      }
      if (signalStrength > 0 && signalStrength <= 10) {
        this.signalStrengthQuality = 'Bad';
      }
    }
    return this.signalStrengthQuality;
  }

  getBatteryPower() {
    let batteryPercent = this.deviceObj?.info?.StatusAnswer?.values?.batteryPercentage as number;
    if (batteryPercent > 50 && batteryPercent <= 100) {
      this.batteryPower = 'Full';
    }
    if (batteryPercent > 20 && batteryPercent <= 50) {
      this.batteryPower = 'Half';
    }
    if (batteryPercent >= 0 && batteryPercent <= 20) {
      this.batteryPower = 'Low';
    }
    return this.batteryPower;
  }

  getTeamName(teamId: string) {
    return this.teamList.find(team => team._id === teamId)?.name;
  }

  async getCGQBioRConnectionState() {
    let portNum = this.deviceObj?.info?.InternalState?.values?.portNumber as number;
    let hubAddress = this.deviceObj?.info?.InternalState?.values?.hubAddress as string;
    let hubName = (await this.deviceService.getDevice(hubAddress).pipe(take(1))
      .toPromise()).name;
    this.connectionState = 'Port ' + portNum.toString() + ' of ' + hubName;
    return this.connectionState;
  }

  async getConnectionState() {
    if (this.deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED') {
      if (this.deviceObj?.deviceInputTypes?.[0] === 'AquilaCGQSensorPlate' ||
      this.deviceObj?.deviceInputTypes?.[0] === 'AquilaBioRSensorPlate') {
        return this.getCGQBioRConnectionState();
      }
      else if (this.deviceObj?.deviceInputTypes?.[0] === 'AquilaCGQBaseStation') {
        this.connectionState = 'USB connected';
      } else {
        this.connectionState = 'Connected';
      }
    } else {
      this.connectionState = 'Not connected';
    }
    return this.connectionState;
  }

  showBlockedByPopout() {
    this.showBlockedPopout = true;
  }

  toggleNameEdit() {
    this.isEditingName = true;
  }

  togglePanIDEdit() {
    this.isEditingPanID = true;
  }

  async savePanID(newPanID: any) {
    if (newPanID != null && isNaN(newPanID) === false) {
      if (this.deviceObj.configuration['1'].values) {
        this.deviceObj.configuration['1'].values.panID = Number(newPanID);
        try {
          await this.deviceService.postDevice(this.deviceObj).toPromise();
        } catch(e: unknown) {
          this.uiNotifications.displayErrorAlert({
            title: 'Error',
            message: 'PanID could not be changed.',
            details: e instanceof Error ? e.message : undefined
          }, e);
        } finally {
          this.isEditingPanID = false;
        }
      } else {
        this.isEditingPanID = false;
      }
    } else {
      this.isEditingPanID = false;
    }
  }


  async saveNameEdit(newName: string) {
    if (newName != '') {
      this.deviceObj.name = newName;
      try {
        await this.deviceService.postDevice(this.deviceObj).toPromise();
      } catch(e: unknown) {
        this.uiNotifications.displayErrorAlert({
          title: 'Error',
          message: 'Device name could not be changed.',
          details: e instanceof Error ? e.message : undefined
        }, e);
      } finally {
        this.isEditingName = false;
      }
    } else {
      this.isEditingName = false;
    }
  }

  private getLISDrivePanID() {
    if (this.deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'
    && this.deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED') {
      let coordinatorID = this.deviceObj?.info?.InternalState?.values?.connectedCoordinator as string;
      this.subscriptions.sink = this.deviceService.getDevice(coordinatorID).subscribe(coordinator => {
        this.connectedCoordinatorPanID = coordinator.configuration['1'].values?.panID as number;
      });
    }
  }

  private getLISDriveChannel() {
    if (this.deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'
    && this.deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED') {
      let coordinatorID = this.deviceObj?.info?.InternalState?.values?.connectedCoordinator as string;
      this.subscriptions.sink = this.deviceService.getDevice(coordinatorID).subscribe(coordinator => {
        this.connectedCoordinatorChannel = coordinator.configuration['2'].values?.channel as number;
      });
    }
  }

  //Currently combines device, hardware versions array, and software versions array into 1 string
  private getLoraPHDOVersion() {
    if (this.deviceObj?.deviceInputTypes?.[0] === 'AquilaLora' ||
      this.deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsDO' ||
      this.deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPH' ||
      this.deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPHDO'
    ) {
      let deviceVersion    = this.deviceObj?.info?.DeviceVersion?.values?.DeviceType as number | undefined;
      let hardwareVersions = this.deviceObj?.info?.DeviceVersion?.values?.HardwareVersion as number[] | undefined;
      let softwareVersions = this.deviceObj?.info?.DeviceVersion?.values?.SoftwareVersion as number[] | undefined;

      let hardwareString = hardwareVersions?.join('.');
      let softwareString = softwareVersions?.join('.');

      this.loraPHDOSoftwareVersion = `${deviceVersion}-${hardwareString}-${softwareString}`;
    }
  }

  containsTrue(arr: unknown) {
    if (Array.isArray(arr)) {
      return (arr as unknown[]).includes(true);
    } else {
      return false;
    }
  }

  setSelectedAction(action: string) {
    this.selectedAction = action;
  }

  getThingieName(thingieId: string | undefined) {
    if (!thingieId) {
      return;
    }
    return this.thingieList.find(thingie => thingie._id === thingieId)?.name;
  }

  loadThingieList() {
    this.subscriptions.sink = this.thingieService.getThingieList().subscribe(thingie => {
      this.thingieList = thingie;
    });
  }

  private loadDeviceDetail(deviceId: string) {

    this.subscriptions.sink = this.deviceService.getDevice(deviceId, true).subscribe(device => {
      this.deviceObj = device;
      this.isDeviceArchived = device.hidden;
      this.getLISDrivePanID();
      this.getLISDriveChannel();
      this.getLoraPHDOVersion();
      this.getConnectionState().then(state => this.connectionState = state);
    });
  }

  translateDeviceTypeToName(deviceType: string|undefined) {
    if (deviceType) {
      return this.deviceNameMap.get(deviceType);
    } else {
      return;
    }
  }

  translateDeviceAction(deviceAction: string) {
    return this.deviceActionMap.get(deviceAction);
  }

  getDeviceName(): string {
    if(!this.deviceObj) {
      return 'Device Details';
    }

    return `Device: ${this.deviceObj.name}`;
  }

  getDeviceActions(deviceId: string) {
    this.subscriptions.sink = this.deviceService.getDeviceActions(deviceId).subscribe(deviceActions => {
      this.deviceActions = deviceActions;
    });
  }

  async onActionSelection(action: string) {
    if (action != 'Actions') {
      const answer = await this.uiNotifications.askForConfirmation(
        `Are you sure you want to execute action '${action}' on device ${this.getDeviceName()}?`
      );

      if (answer !== ConfirmationResults.Yes) {
        return;
      }

      try {
        await this.deviceService.executeDeviceAction(this.deviceId, action).toPromise();
        this.uiNotifications.displaySnackbar({
          message: 'Action execution successful',
        });

      } catch(e: unknown) {
        this.uiNotifications.displayErrorAlert({
          title: 'Error',
          message: 'Action execution failed',
          details: e instanceof Error ? e.message : undefined
        }, e);
      }
    }
  }


  async onResetStatus() {
    var resetConfirmationDialog: ConfirmationDialogOptions = {
      message: 'Are you sure you want to <b>reset  the device:' + `${this.getDeviceName()}?</b>`,
      title: `Reset Device ${this.getDeviceName()}`,
      approvalButton: {
        text: 'Reset',
        color: 'warn',
      },
      rejectionButton: {
        text: 'Cancel',
      },
    };
    const answer = await this.uiNotifications.askForConfirmation(
      resetConfirmationDialog
    );

    if (answer !== ConfirmationResults.Yes) {
      return;
    }

    await this.deviceService.resetDevice(this.deviceObj.address);
  }

  showAdditionalInformation(event: MatSlideToggleChange) {
    this.showMore = event.checked;
  }

  showTeamDetail(team: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/team-detail/general/', team])
    );

    window.open(url, '_blank');
  }

  showThingieDetail(thingieId: string | undefined) {
    if (!thingieId) {
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/thingies/thingie/', thingieId])
    );

    window.open(url, '_blank');
  }

  showDeviceDetail(deviceId: string | undefined) {
    if (!deviceId) {
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/devices-debug/device/', deviceId])
    );

    window.open(url, '_blank');
  }

  async toggleDevicesArchived() {
    // toggle
    const isDeviceToggle = !this.isDeviceArchived;
    if (isDeviceToggle === true) {
      // hide device
      await this.deviceService.archiveDevice(this.deviceId);
      await this.uiNotifications.displaySnackbar({
        message: 'Device was Archived'
      });
    }
    else{
      // show device
      await this.deviceService.unarchiveDevice(this.deviceId);
      await this.uiNotifications.displaySnackbar({
        message: 'Device was Restored'
      });
    }

    this.isDeviceArchived = isDeviceToggle;
  }
}


@Injectable({
  providedIn: 'root'
})
export class DeviceDetailDialogService {
  constructor(private dialogSvc: MatDialog) {}

  open(deviceId: string) {
    return this.dialogSvc.open<
    DeviceDetailDialogComponent,
    string,
    null
    >(
      DeviceDetailDialogComponent,
      { data: deviceId }
    );
  }
}
