import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { NGXLogger } from 'ngx-logger';
import { v4 } from 'uuid';
import { LogService } from '../logger.service';

declare global {
  interface Document {
    documentMode?: any;
  }
}

@Injectable()
export class LogServiceImpl implements LogService {

  private session_uuid = v4();

  constructor(
    private keycloak: KeycloakService,
    private ngXLogger: NGXLogger
  ) {
  }

  getUserID() {
    return this.keycloak.getKeycloakInstance().subject;
  }

  public sessionAndUserId() {
    return 'Session/User ID: ' + this.session_uuid + '/' + this.getUserID();
  }

  debug(message: any, ...additional: any[]) {
    additional = [this.sessionAndUserId(), ...additional];
    this.ngXLogger.debug(message, ...additional);
  }

  info(message: any, ...additional: any[]) {
    additional = [this.sessionAndUserId(), ...additional];
    this.ngXLogger.info(message, ...additional);
  }

  warn(message: any, ...additional: any[]) {
    additional = [this.sessionAndUserId(), ...additional];
    this.ngXLogger.warn(message, ...additional);
  }

  error(message: any, ...additional: any[]) {
    additional = [this.sessionAndUserId(), ...additional];
    this.ngXLogger.error(message, ...additional);
  }

}
