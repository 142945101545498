import { Injectable } from '@angular/core';
import { LogServiceImpl } from './implementation/logger.service';

@Injectable({
  providedIn: 'root',
  useClass: LogServiceImpl
})
export abstract class LogService {

  abstract debug(message: any, ...additional: any): void;

  abstract info(message: any, ...additional: any): void;

  abstract warn(message: any, ...additional: any): void;

  abstract error(message: any, ...additional: any): void;

}
