import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendAvailabilityServiceImpl } from './implementation/backend-availability.impl.service';

export interface Status {
  anyUnavailable: boolean | undefined;
  dataProcessing: boolean | undefined;
  deviceManagement: boolean | undefined;
  logManagement: boolean | undefined;
  notifications: boolean | undefined;
  search: boolean | undefined;
}

@Injectable({
  providedIn: 'root',
  useClass: BackendAvailabilityServiceImpl
})
export abstract class BackendAvailabilityService {
  abstract didErrorsHappen: boolean;
  abstract startChecking(): void;
  abstract get status$(): Observable<Status>;
  abstract get anyUnavailable$(): Observable<boolean>;
  abstract checkAvailability(): void;
}
