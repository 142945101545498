import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';

@Injectable({
  providedIn: 'root'
})
export class AdminAccessGuard implements CanActivate, CanLoad {

  constructor(
    private auth: AuthService,
    private log: LogService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    UNUSED_ARGS(route, state);

    try {
      return await this.auth.isAdmin();
    } catch(err: any) {
      this.log.error(err.message);
      return false;
    }
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
    UNUSED_ARGS(route, segments);

    return await this.auth.isAdmin();
  }

}
