import { combineLatest, ConnectableObservable, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, publishReplay, startWith } from 'rxjs/operators';

export function bufferWhileTabIsHidden<T>() {
  return (source: Observable<T>) => {
    const tabVisibilityEvent$ = fromEvent(document, 'visibilitychange').pipe(
      map(() => document.hidden),
      startWith(document.hidden)
    );
    // combineLatest these observables, such that if the tab is
    // hidden, updates are filtered out. If the tab is activated, the
    // latest obj was cached and will be returned immediately
    return combineLatest([source, tabVisibilityEvent$]).pipe(
      // only output when the tab is visible
      filter(([,hidden]) => hidden === false),
      // output the source observable object
      map(([obj,]) => obj),
    );
  };
}

/**
 * Make a ConnectableObservable behave like a ordinary observable and automates the way you can connect to it.
 *
 * Like the original refCount operator, but disconnects the source observable only after a delay has passed.
 *
 * @param delay delay in ms after which to unsubscribe the source
 * @returns a shared version of the source Observable that automatically connects to the source observable
 *    and disconnects when no subscribers are left **and** the delay has passed.
 */
export function delayedRefCount<T>(delay: number) {
  return function transform(obs: Observable<T>) {
    const connectableObs = obs as ConnectableObservable<T>;

    if (connectableObs.connect === undefined) {
      throw new Error('Trying to apply delayedRefCount operator to an observable that is no ConnectableObservable.');
    }

    let subscribers = 0;
    let connection: Subscription | undefined;
    let timeout: ReturnType<typeof setTimeout> | undefined;

    return new Observable<T>((subscriber) => {
      subscribers++;

      if (timeout !== undefined) {
        // cancel pending unsubscribe
        clearTimeout(timeout);
        timeout = undefined;
      }

      // Begin emitting values
      if (connection === undefined) {
        connection = connectableObs.connect();
      }

      connectableObs.subscribe(subscriber);

      return function teardown() {
        if (--subscribers === 0) {
          timeout = setTimeout(
            () => {
              if (connection !== undefined) {
                connection.unsubscribe();
                connection = undefined;
              }

              timeout = undefined;
            },
            delay
          );
        }
      };
    });
  };
}

/**
 * Like the original shareReplay operator, but uses delayedRefCount
 *
 * @param delay delay in ms after which to unsubscribe the source
 * @param buffer number of buffered elements
 * @returns a shareReplay'd version of the source observable that only resets after a set
 *   delay time without subscribers
 */
export function delayedShareReplay<T>(delay: number, buffer: number) {
  return (obs: Observable<T>) => obs.pipe(publishReplay(buffer), delayedRefCount(delay));
}
