import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IUser } from 'src/app/model/user-management/user';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserListResolverService implements Resolve<IUser[]> {

  constructor(private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): IUser[] | Observable<IUser[]> | Promise<IUser[]> {
    UNUSED_ARGS(route, state);

    return this.userService.getUserList();
  }
}
