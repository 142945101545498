import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';
import { UNUSED_ARGS } from './utility/unused-arguments';

@Injectable({
  providedIn: 'root'
})
export class CustomRouteReuseStrategy implements BaseRouteReuseStrategy {
  dashboardTree?: DetachedRouteHandle;

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if(this.getPathFromRoot(route).includes('/dashboard/cards')) {
      return true;
    }

    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    if (!route.component) {
      return;
    }
    if(this.getPathFromRoot(route).includes('/dashboard/cards')) {
      this.dashboardTree = detachedTree;
    }
    UNUSED_ARGS(route, detachedTree);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (!route.component) {
      return false;
    }
    if(this.dashboardTree !== undefined && this.getPathFromRoot(route).includes('/dashboard/cards')) {
      return true;
    }

    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.component) {
      return null;
    }
    if(this.dashboardTree !== undefined && this.getPathFromRoot(route).includes('/dashboard/cards')) {
      return this.dashboardTree;
    }

    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if(this.getPathFromRoot(future).includes('/thingies/create')) {
      return false;
    }
    // if(this.getPathFromRoot(curr).includes('/dashboard/cards') && this.getPathFromRoot(future).includes('/dashboard/cards')) {
    //   return true;
    // }
    if(this.getPathFromRoot(curr).includes('/dashboard/devices') && this.getPathFromRoot(future).includes('/devices/')) {
      return true;
    }
    if(this.getPathFromRoot(curr).includes('/devices/device') && this.getPathFromRoot(future).includes('/devices/device')) {
      return true;
    }
    if(this.getPathFromRoot(curr).includes('/devices/') && this.getPathFromRoot(future).includes('/dashboard/devices')) {
      return true;
    }

    return future.routeConfig === curr.routeConfig;
  }

  getPathFromRoot(route: ActivatedRouteSnapshot) {
    return route.pathFromRoot
      .map(snapshot => snapshot.url.map(segment => segment.path).join('/'))
      .join('/') + '/';
  }
}
