export const i18nEnglish = {
  assets: {
    icons: {
      byDeviceType: {
        AquilaLisDrive: '/assets/device-icons/LIS-drive.svg',
        AquilaLisCoordinator: '/assets/device-icons/lis-coordinator.svg',
        AquilaCGQSensorPlate: '/assets/device-icons/CGQ-sensor.svg',
        AquilaBioRSensorPlate: '/assets/device-icons/bior-sensor.svg',
        AquilaDotsPHDO: '/assets/device-icons/pH-DO.svg',
        AquilaDotsPH: '/assets/device-icons/pH.svg',
        AquilaDotsDO: '/assets/device-icons/DO.svg',
        _unknown_: '/assets/device-icons/unknown-device.svg',
      },
      byConnectionState: {
        Connected: '/assets/connection-icons/Connected-Icon.svg',
        Working: '/assets/connection-icons/Connected-Icon.svg',
        NotConnected: 'assets/connection-icons/NotConnected-Icon.svg',
        Error: 'assets/connection-icons/Error-Icon.svg',
      },
      bySignalStrength: {
        Excellent: '/assets/signal-strength-icons/very-good.svg',
        Great: '/assets/signal-strength-icons/good.svg',
        Good: '/assets/signal-strength-icons/ok.svg',
        Bad: '/assets/signal-strength-icons/bad.svg',
        Poor: '/assets/signal-strength-icons/no-signal.svg',
      }
    },

    illustrations: {
      thingie: {
        flask: '',
        bioreactor: '',
      },
      byDeviceType: {
        AquilaLisDrive: '/assets/device-illustrations/LIS-drive.svg',
        AquilaLisCoordinator: '/assets/device-illustrations/lis-coordinator.svg',
        AquilaCGQSensorPlate: '/assets/device-illustrations/CGQ-sensor.svg',
        AquilaDotsPHDO: '/assets/device-illustrations/DOTS-pH-DO.svg',
        AquilaDotsPH: '/assets/device-illustrations/DOTS-pH.svg',
        AquilaDotsDO: '/assets/device-illustrations/DOTS-DO.svg',
        AquilaBioRSensorPlate: '/assets/device-illustrations/bior-sensor.svg',
        AquilaCGQBaseStation: '/assets/device-illustrations/CGQ-Base-Station-8.svg',
        _unknown_: '/assets/device-illustrations/other-device.svg',
      },
    },
  },

  deviceLabels: {
    AquilaLora: 'Multiparameter sensor',
    AquilaLisDrive: 'LIS drive',
    AquilaLisCoordinator: 'LIS coordinator',
    AquilaCGQSensorPlate: 'CGQ sensor plate',
    AquilaBioRSensorPlate: 'BioR sensor plate',
    AquilaCGQBaseStation: 'CGQ base station',
    AquilaDotsPHDO: 'pH/DO sensor',
    AquilaDotsPH: 'pH sensor',
    AquilaDotsDO: 'DO sensor',
    _unknown_: 'Unknown device: {{type}}',
  },
};
