import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceDetailDialogService } from 'src/app/components/dialogs/device-detail-dialog/device-detail-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetailDialogOpenGuard implements CanLoad {

  constructor(
    private deviceDialog: DeviceDetailDialogService,
    private router: Router
  ) {
  }

  /**
   * The CanLoad implementation for the /devices/device/:deviceId route.
   * This is actually not a real canLoad function, because we don't want to navigate when the user
   * clicks a link to this; instead we want to stay where we are, but open a device detail dialog.
   * If this is the first navigation that happens (e.g. the user opens this URL in a new tab)
   * open the dialog and navigate to the device dashboard in the background.
   *
   * @param route route configuration of the /device/:deviceId subroute
   * @param segments segments of matching url of this route, in this case ['device', deviceId]
   * @returns whether we allow the navigation - false always
   */
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // when navigating to this url, open the device dialog for the requested deviceID
    if (segments[0].path === 'device' && segments[1] !== undefined) {
      this.deviceDialog.open(segments[1].path);
    }

    if (this.router.url === '/') {
      // this is the user session's first page; open something in the background
      this.router.navigateByUrl('/dashboard/devices');
    }

    // never finish this navigation
    return false;
  }

}
