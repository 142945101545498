import { Injectable } from '@angular/core';
import { IDialogParams as ConfigurableDialogOptions } from 'src/app/components/dialogs/configurable-dialog/configurable-dialog.component';
import { ConfirmationDialogOptions, UINotificationServiceImpl } from './implementation/uinotification.service';
export { ConfirmationDialogOptions } from './implementation/uinotification.service';

export interface ISnackOptions {

  /**
   * @property {number} duration - how long the toast should
   * be visible; in millisecons
   */
  durationMilliseconds?: number;

  /**
   * @property {string} message - the text to display
   */
  message: string;

  /**
   * @property {} action - optional action button
   *
   * Clicking the button will dismiss the modal
   * and call any callbacks that were provided.
   */
  action?: {

    /**
     * The text of the action button
     */
    text: string;

    /**
     * List of functions to be called
     * when the action button was pressed.
     *
     * Functions will be called(!) in order
     * they are provided.
     */
    actionsToExecute: { (): void }[];
  };
}

export enum ConfirmationResults {
  Yes,
  No,
  Ok = Yes,
  Cancel = No
}

export interface IErrorAlertOptions {
  title: string;
  message: string;

  /**
   * If details is of type object it will be converted
   * to it's JSON representation
   */
  details?: string | object;
}

@Injectable({
  providedIn: 'root',
  useClass: UINotificationServiceImpl
})
export abstract class UINotificationService {

  /**
   * Use this function to show messages in a snackbar
   *
   * @param opts
   */
  abstract displaySnackbar(opts: ISnackOptions): Promise<void>;

  /**
   * Use this function to ask for answers on simple questions
   *
   * @param opts Either a string containing the message
   * which is shown to the user, or a configuration object
   * facilitating a more customized dialog.
   */
  abstract askForConfirmation(opts: string | ConfirmationDialogOptions): Promise<ConfirmationResults>;

  /**
   * Use this function to show information in a dialog
   *
   * @param opts A configuration object
   * facilitating a more customized dialog.
   */
  abstract showMessageDialog(opts: ConfigurableDialogOptions): Promise<any>;

  /**
   * Use this function to display errors
   *
   * @param opts
   */
  abstract displayErrorAlert(opts: IErrorAlertOptions, error: any): Promise<void>;

  /**
   * Prompt the user to enter some text
   *
   * @param prompt The prompt shown to the user
   */
  abstract prompt(prompt: string, _default?: string): Promise<string | undefined>;
}
