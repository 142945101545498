import { IPojoDevice } from '../model/device/IPojoDevice';
import { ITaskInputType } from '../model/thingie/task';
import * as jsonpatch from 'fast-json-patch';

export function addConfigItemFromInputToDevice(device: IPojoDevice, input: ITaskInputType) {


  let configurationToBeAssigned = configFromInput(input);
  addConfigItemToDevice(device, configurationToBeAssigned);
}

export function addConfigItemToDevice(device: IPojoDevice, configurationToBeAssigned: { type: string; values: { [key: string]: any } }) {
  // is this even a config?
  if (device.info[configurationToBeAssigned.type] !== undefined) {
    // this is a subscription for info packets; nothing to do
    return;
  }

  // first, let's see if the config is already there exactly as requested.
  for (let [configKey, config] of Object.entries(device.configuration)) {
    const diff = jsonpatch.compare(config.values ?? {}, configurationToBeAssigned.values);
    if (config.type === configurationToBeAssigned.type) {
      // this slot matches the type
      if (diff.length === 0) {
        // config is already there, nothing to do
        return;
      }
      if (config.fixed === true) {
        // This is a fixed slot, so assign it right away
        device.configuration[configKey].values = configurationToBeAssigned.values;
        return;
      }
    }
    // no luck, try the next one
  }

  // now find the next free slot and assign
  let configKeys = new Set(Object.keys(device.configuration));
  let isAssigned = false;
  for (let configIdx = 1; !isAssigned; configIdx++) {
    let configKey = `${configIdx}`;
    if (configKeys.has(configKey)) {
      // blocked configKey; try the next one
      continue;
    }

    // assign
    device.configuration[configKey] = {
      ...configurationToBeAssigned,
      fixed: false
    };
    isAssigned = true;
  }
}

export function removeConfigItemFromDevice(device: IPojoDevice, input: ITaskInputType) {
  let configurationToBeRemoved = configFromInput(input);

  // is this even a config?
  if (device.info[configurationToBeRemoved.type] !== undefined) {
    // this is a subscription for info packets; nothing to do
    return;
  }

  // Find it in existing configs
  for (let [key, config] of Object.entries(device.configuration)) {
    const diff = jsonpatch.compare(config.values ?? {}, configurationToBeRemoved.values);
    if (config.type === configurationToBeRemoved.type && diff.length === 0) {
      // This config matches
      if (config.fixed === true) {
        // not possible to remove fixed items
        // TODO: probably some cleanup should happen here, though...
        return;
      }
      //Remove it
      delete device.configuration[key];
    }
  }
}

function configFromInput(input: ITaskInputType) {
  if (input.source !== 'device') {
    throw new Error(`Can't assign non-device inputs. Weird.`);
  }
  let values = input.config;
  if (values === undefined) {
    throw new Error(`Template without device config not supported`);
  }

  const config = {
    type: input.configPacketType,
    values: values
  };
  return config;
}
