<div 
  class="wrapper"
  [ngClass]="{
    'single-line': !isMultiwordAction,
    'multi-line': isMultiwordAction
  }"
>
  <div>
    <span class="message">{{ message }}</span>
  </div>

  <div *ngIf="action" class="action-wrapper">
    <button
      mat-button
      (click)="onAction()"
    >
      {{ action }}
    </button>
  </div>
</div>
