import { ErrorHandler, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { UINotificationService } from '../../ui-notification/uinotification.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandler {

  msgs = new Subject<any>();
  subscriptions = new SubSink();

  constructor(
    private uiNotifications: UINotificationService
  ) {
    super();
  }

  handleError(error: any) {
    this.uiNotifications.displayErrorAlert({
      title: 'Unhandled Error',
      message: 'An uncaught exception occurred (see details for more info)',
      details: undefined
    },
    error);
  }
}
