import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, from, merge, Observable, of } from 'rxjs';
import { finalize, map, startWith, switchMap, take } from 'rxjs/operators';
import { TaskKey } from 'src/app/model/backend';
import { DeviceSeries } from 'src/app/model/device/device-series';
import { IPacketContentItem } from 'src/app/model/device/IPacketContentItem';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { IDevice } from 'src/app/model/thingie/task';
import { IDataEvent, IDeviceFilter, IFilter, WebsocketConnectionService } from 'src/app/services/common/websocket-connection.service';
import { deepCopy } from 'src/app/utility/deep-copy';
import { deepFreeze } from 'src/app/utility/deep-freeze';
import { createDefaultDeviceDataSeries } from 'src/app/utility/default-object-creator';
import { addConfigItemFromInputToDevice } from 'src/app/utility/device-configuration';
import { bufferWhileTabIsHidden, delayedShareReplay } from 'src/app/utility/rxjs';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { DeviceService, IDataInventoryResponse, IDeviceTemplate } from '../device.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceServiceImpl implements DeviceService, OnDestroy {

  deviceUrl             = DefaultConfig.uris.device;
  createDeviceUrl       = DefaultConfig.uris.createDevice;
  deviceUrlDryRun       = DefaultConfig.uris.deviceDryRun;
  deviceListUrl         = DefaultConfig.uris.deviceList;
  deviceActions         = DefaultConfig.uris.deviceActions;
  deviceActionExecution = DefaultConfig.uris.deviceActionExecution;
  cocoTemplateUrl       = DefaultConfig.uris.deviceTemplateCoco;
  dataInventoryUrl      = DefaultConfig.uris.dataInventory;
  disableDeviceUri      = DefaultConfig.uris.deviceDisablePart;
  enableDeviceUri       = DefaultConfig.uris.deviceEnablePart;
  deviceForceDevice     = DefaultConfig.uris.deviceForceUnblock;
  removeDeviceConfig    = DefaultConfig.uris.removeDeviceConfig;
  resetDeviceUri        = DefaultConfig.uris.resetDevice;

  websocketUri = DefaultConfig.uris.deviceWebsocket;

  private deviceCache: Map<string, Observable<IPojoDevice>> = new Map();

  private subscriptions = new SubSink();

  constructor(
    private httpClient: HttpClient,
    private websocketConnection: WebsocketConnectionService,
  ) {

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getDevice(id: string, writable?: boolean, initial?: IPojoDevice): Observable<IPojoDevice> {
    let device$ = this.deviceCache.get(id);
    if(device$ !== undefined) {
      if (writable === true) {
        // return a copy
        return device$
          .pipe(switchMap(device => from(deepCopy(device))));
      }

      // return the original device, which is a deepFrozen object that cannot be changed
      return device$;
    }

    const oneTimeRequest$ = initial !== undefined ? of(initial) : this.httpClient.get<IPojoDevice>(
      this.deviceUrl.replace('{deviceId}', encodeURIComponent(id))
    );

    const webSocketUpdates$ = this.subscribeUpdates(id).pipe(
      map(event => event.data as IPojoDevice),
    );

    const deviceAndUpdates$: Observable<IPojoDevice> = merge(oneTimeRequest$, webSocketUpdates$).pipe(
      finalize(() => this.deviceCache.delete(id)),
      delayedShareReplay(DefaultConfig.device.shareReplayDelaySeconds * 1000, 1),
      map(device => deepFreeze(device)),
    );

    this.deviceCache.set(id, deviceAndUpdates$);

    return deviceAndUpdates$.pipe(switchMap(device => from(deepCopy(device))));
  }

  getDevices(): Observable<IPojoDevice[]> {
    const deviceList$ = this.httpClient.get<IPojoDevice[]>(this.deviceListUrl);
    const deviceCreationFilter: IFilter = {
      filterType: 'DeviceCreationFilter'
    };
    const deviceCreationNotification$ = this.websocketConnection.subscribeWithFilter(this.websocketUri, deviceCreationFilter);

    return deviceCreationNotification$.pipe(
      startWith(undefined),
      switchMap(() => deviceList$),
      switchMap(devices => {
        const devices$ = devices.map(device => this.getDevice(device.address, true, device));

        if (devices$.length === 0) {
          return of([]);
        }
        return combineLatest(devices$);
      })
    );
  }

  postDevice(device: IPojoDevice) {
    return this.httpClient.post<IPojoDevice>(this.deviceUrl.replace('{deviceId}', encodeURIComponent(device.address)), device);
  }

  createDevice(device: IPojoDevice) {
    return this.httpClient.post<IPojoDevice>(this.createDeviceUrl, device);
  }

  getDeviceTemplate(version: string): Observable<IDeviceTemplate> {
    return this.httpClient.get<IDeviceTemplate>(this.cocoTemplateUrl.replace('{version}', version));
  }

  getDeviceActions(deviceId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(this.deviceActions.replace('{deviceId}', encodeURIComponent(deviceId)));
  }

  executeDeviceAction(deviceId: string, action: string, parameters?: any) {
    return this.httpClient.post<any>(
      this.deviceActionExecution
        .replace('{deviceId}', encodeURIComponent(deviceId))
        .replace('{action}', action),
      parameters ? { parameters: parameters } : null
    );
  }

  getDataInventory(address: string): Observable<IDataInventoryResponse> {
    return this.httpClient.get<IDataInventoryResponse>(
      this.dataInventoryUrl.replace('{deviceAddress}', encodeURIComponent(address))
    );
  }

  forceUnblockDevice(deviceId: string, taskkey: TaskKey) {
    return this.httpClient.post<any>(this.deviceForceDevice.replace('{deviceId}', encodeURIComponent(deviceId))
      .replace('{thingieId}', encodeURIComponent(taskkey.thingie))
      .replace('{processIdx}', taskkey.processIdx.toString())
      .replace('{taskIdx}', taskkey.taskIdx.toString()), undefined);
  }

  async getDeviceDataSeries(deviceId: string): Promise<DeviceSeries[]> {
    const device = await this.getDevice(deviceId)
      .pipe(
        take(1)
      )
      .toPromise();


    //// Select all device data series from shortcuts where the
    //// shortcut contains the packet type and property name information
    //// and has a representation within `packetContentDescription`

    const dataSeries: DeviceSeries[] = [];

    for(const key in device.shortcuts) {
      const unparsedShortcut = device.shortcuts[key];
      const parts            = unparsedShortcut.split('.');
      if(parts.length !== 4) {
        continue;
      }

      const packetType = parts[1];
      const property   = parts[3];

      // Does the shortcut have a descriptor?...
      if(device.packetContentDescription[packetType] !== undefined) {

        // Does the descriptor contain a descriptor for the property?....
        if(device.packetContentDescription[packetType].items[property] !== undefined) {
          // Does the descriptor contain value description?

          if(device.packetContentDescription[packetType].items[property].valueInfo[0] !== undefined) {
            const packet: IPacketContentItem = {
              structureInfo: device.packetContentDescription[packetType].items[property].structureInfo,
              visibility: device.packetContentDescription[packetType].items[property].visibility,
              valueInfo: device.packetContentDescription[packetType].items[property].valueInfo
            };

            // Does the visibility of the packet content description
            if ( packet.visibility !== 'hidden') {
              dataSeries.push({
                ...createDefaultDeviceDataSeries(),
                identification: {
                  type: 'deviceSeries',
                  packetType,
                  property,
                  deviceAddress: device.address
                },
                dataItemDescription: {
                  [`${property}`]: packet
                }
              });

            }//packet content description
          } // value description
        } // property descriptor
      } // shortcut descriptor
    } // for...in

    return dataSeries;
  }

  async deleteConfig(deviceAddress: string, handleId: string): Promise<IPojoDevice> {
    if (deviceAddress === undefined) {
      throw new Error('Cannot delete from device if no device address is selected');
    }

    const path = this.removeDeviceConfig;
    return await this.httpClient.delete<IPojoDevice>(path
      .replace('{deviceAddress}', encodeURIComponent(deviceAddress))
      .replace('{handleID}', handleId)).toPromise();
  }

  async checkResources(device: IPojoDevice): Promise<IPojoDevice> {
    return await this.httpClient.post<IPojoDevice>(
      this.deviceUrlDryRun.replace('{deviceId}', encodeURIComponent(device.address)),
      device
    ).toPromise();
  }

  subscribeUpdates(deviceId?: string): Observable<IDataEvent<IPojoDevice>> {
    let requestFilter: IDeviceFilter;
    if (deviceId) {
      if (deviceId.includes('://')) {
        // this is an address
        requestFilter = {
          filterType: 'DeviceFilter',
          address: deviceId
        };
      } else {
        // it is an objectid
        requestFilter = {
          filterType: 'DeviceFilter',
          _id: deviceId
        };
      }
    } else {
      // a match-all filter
      requestFilter = {
        filterType: 'DeviceFilter'
      };
    }

    return this.websocketConnection.subscribeWithFilter<IPojoDevice>(this.websocketUri, requestFilter)
      .pipe(bufferWhileTabIsHidden());
  }

  /**
   * Disable device
   *
   * @param device - The device's id or address
   * @returns The device object after modification
   */
  async disableDevice(opts: { address: string }) {
    return await this.httpClient.post<IPojoDevice>(
      this.disableDeviceUri.replace(
        '{address}',
        encodeURIComponent(opts.address)
      ),
      undefined
    ).toPromise();
  }

  /**
   *
   * @param device - The device's id or address
   * @returns The device object after modification
   */
  async enableDevice(opts: { address: string }) {
    return await this.httpClient.post<IPojoDevice>(
      this.enableDeviceUri.replace(
        '{address}',
        encodeURIComponent(opts.address)
      ),
      undefined
    ).toPromise();
  }

  async uploadConfig(res: IDevice): Promise<IPojoDevice> {
    if (res.deviceAddress === undefined) {
      throw new Error('No device assigned');
    }
    let dev = await this.getDevice(res.deviceAddress, true).pipe(take(1))
      .toPromise();
    addConfigItemFromInputToDevice(dev, res);
    dev = await this.postDevice(dev).toPromise();
    return dev;
  }


  resetDevice(address: string): Promise<void> {
    return this.httpClient.post<void>(
      this.resetDeviceUri.replace(
        '{address}',
        encodeURIComponent(address)
      ), {}
    ).toPromise();
  }

  archiveDevice(deviceId: string): Promise<void> {
    return this.httpClient.post<void>(
      DefaultConfig.uris.deviceArchive.replace('{deviceId}', deviceId), {}
    ).toPromise();
  }

  unarchiveDevice(deviceId: string): Promise<void> {
    return this.httpClient.delete<void>(
      DefaultConfig.uris.deviceArchive.replace('{deviceId}', deviceId), {}
    ).toPromise();
  }

}
