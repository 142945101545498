import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { interval, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, throttleTime } from 'rxjs/operators';
import { BackendUnavailabeDialogComponent } from 'src/app/components/dialogs/backend-unavailabe-dialog/backend-unavailabe-dialog.component';
import { deepCopySync } from 'src/app/utility/deep-copy';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { LogService } from '../../loggers/logger.service';
import { BackendAvailabilityService, Status } from '../backend-availability.service';

@Injectable()
export class BackendAvailabilityServiceImpl implements BackendAvailabilityService {

  backendUnavailableDialogRef?: MatDialogRef<BackendUnavailabeDialogComponent,any>;
  dialogOpenFunction: any;
  didErrorsHappen: boolean = false;

  constructor(
    private http: HttpClient,
    private log: LogService,
    private keycloak: KeycloakService,
    private dialog: MatDialog,
  ) {
    this.init();
  }

  startChecking(): void {
    this.subscriptions.sink = this.anyUnavailable$.pipe(
      distinctUntilChanged()
    ).subscribe(backendState => {
      if (backendState === true) {
        this.didErrorsHappen = false;
        this.backendUnavailableDialogRef = this.dialog.open(BackendUnavailabeDialogComponent, { disableClose: true });

        // block dialog opening until backend is available again
        this.dialogOpenFunction = this.dialog.open;
        this.dialog.open = () => {
          const ref = this.dialogOpenFunction(arguments);
          ref.close();
          return ref;
        };
      }

      if (backendState === false) {
        if (this.backendUnavailableDialogRef !== undefined) {
          if (this.didErrorsHappen === false) {
            this.backendUnavailableDialogRef.close();
          } else {
            this.backendUnavailableDialogRef.disableClose = false;
            this.backendUnavailableDialogRef.componentInstance.askForRefresh = true;
          }
        }
        // restore dialog open function
        if (this.dialogOpenFunction !== undefined) {
          this.dialog.open = this.dialogOpenFunction;
          this.dialogOpenFunction = undefined;
        }
      }
    });
  }

  private checkRunning = false;

  status: Status = {
    anyUnavailable: undefined,
    dataProcessing: undefined,
    deviceManagement: undefined,
    logManagement: undefined,
    notifications: undefined,
    search: undefined
  };

  private _checkAvailabilityTrigger$ = new Subject<false>();
  private _status$ = new Subject<Status>();
  public status$ = this._status$.pipe(shareReplay({
    refCount: true,
    bufferSize: 1,
    windowTime: 300
  }));
  private _anyUnavailable$ = new ReplaySubject<boolean>(1);
  public anyUnavailable$ = this._anyUnavailable$.asObservable();

  private subscriptions = new SubSink();

  init(): void {
    const keycloakReadyEvent$ = this.keycloak.keycloakEvents$.pipe(
      filter((evt) => evt.type === KeycloakEventType.OnReady),
      take(1)
    );
    this.subscriptions.sink = keycloakReadyEvent$.pipe(switchMap(() =>
      this._checkAvailabilityTrigger$.pipe(
        startWith(false),
        throttleTime(300),
        switchMap(() => interval(DefaultConfig.serviceStatusBar.intervalMilliseconds).pipe(
          startWith(false),
          map(() => false)
        )),
      ))).subscribe(() => this.runChecks());

  }

  stop() {
    this.subscriptions.unsubscribe();
  }

  checkAvailability() {
    this._checkAvailabilityTrigger$.next(false);
  }

  /**
   * Check & update the data processing service's status.
   *
   * @returns {Promise}
   * @throws only if logging fails
   */
  private async checkDataProcessing() {
    try {
      await this.makeTestRequest(DefaultConfig.uris.version.dataprocessing);
      this.status.dataProcessing = true;
      this.log.debug('Data Processing is healthy');
    } catch(ex: unknown) {
      this.status.dataProcessing = false;
      console.error('Data Processing is bad');
    }
  }

  /**
   * Check & update the device management service's status.
   *
   * @throws only if logging fails
   */
  private async checkDeviceManagement(): Promise<any> {
    try {
      await this.makeTestRequest(DefaultConfig.uris.version.devicemanagement);
      this.status.deviceManagement = true;
      this.log.debug('Device Management is healthy');
    } catch(ex) {
      this.status.deviceManagement = false;
      console.error('Device Management is bad');
    }
  }

  /**
   * Check & update the log service's status.
   *
   * @returns {Promise}
   * @throws only if logging fails
   */
  private async checkLogManagement() {
    try {
      await this.makeTestRequest(DefaultConfig.uris.version.logmanagement);
      this.status.logManagement = true;
      this.log.debug('Log Management is healthy');
    } catch(ex) {
      this.status.logManagement = false;
      console.error('Log Management is bad');
    }
  }

  /**
   * Check & update the notification service's status.
   *
   * @returns {Promise}
   * @throws only if logging fails
   */
  private async checkNotifications() {
    try {
      await this.makeTestRequest(DefaultConfig.uris.version.notification);
      this.status.notifications = true;
      this.log.debug('Notifications is healthy');
    } catch(ex) {
      this.status.notifications = false;
      console.error('Notifications is bad');
    }
  }

  /**
   * Check & update the search service's status.
   *
   * @returns {Promise}
   * @throws only if logging fails
   */
  private async checkSearch() {
    try {
      await this.makeTestRequest(DefaultConfig.uris.version.search);
      this.status.search = true;
      this.log.debug('Search is healthy');
    } catch(ex) {
      this.status.search = false;
      console.error('Search is bad');
    }
  }

  private async makeTestRequest(servicePathPart: string) {
    await this.http.get(
      servicePathPart,
      {
        responseType: 'text'
      }
    )
      .toPromise();
  }

  private runChecks() {
    if(this.checkRunning) {
      console.debug('A service check is running already.');
      return;
    }

    this.checkRunning = true;
    console.debug('Running service check...');

    const checks: Promise<any>[] = [];
    checks.push(this.checkDataProcessing());
    checks.push(this.checkDeviceManagement());
    checks.push(this.checkLogManagement());
    checks.push(this.checkNotifications());
    checks.push(this.checkSearch());

    Promise.all(checks).then(_ => {
      console.debug('Service check complete');
      const anyUnavailable = this.status.dataProcessing === false ||
        this.status.deviceManagement === false ||
        this.status.logManagement === false ||
        this.status.notifications === false ||
        this.status.search === false;
      this.status.anyUnavailable = anyUnavailable;

      this._status$.next(deepCopySync(this.status));
      this._anyUnavailable$.next(anyUnavailable);
      this.checkRunning = false;
    });
  }
}
