/**
 * Recursively freezes and object
 *
 * @param instance The object to freeze
 * @returns The object passed in
 */
export function deepFreeze<T>(instance: T): T {
  if(typeof instance !== 'object') {
    throw new Error();
  }

  for(const prop in instance) {
    if(typeof instance[prop] === 'object' && !Object.isFrozen(instance[prop])) {
      deepFreeze(instance[prop]);
    }
  }

  return Object.freeze(instance);
}
