import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ITeam } from 'src/app/model/user-management/team';
import { UNUSED_ARGS } from 'src/app/utility/unused-arguments';
import { TeamService } from '../../team/team.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsResolverService implements Resolve<ITeam[]> {

  constructor(private teamService: TeamService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ITeam[]> {
    UNUSED_ARGS(route, state);

    return await this.teamService.getTeamList();
  }
}
