<h3
  mat-dialog-title
>
  {{ _title }}
</h3>

<div mat-dialog-content>
  <p *ngFor="let part of _message" [innerHTML]="part"></p>
</div>

<div class="custom-dialog">
  <mat-form-field 
    *ngIf="this._formControl"
    [matTooltip]="this._tooltip ? this._tooltip:'' "
  >
    <mat-error
      data-cy-id-tc="duplicate-warning">
      {{ _formControl.errors | pureFnCall:  getInputErrorMessage }}
    </mat-error>
    <input
      [formControl]="_formControl"
      data-cy-id-tc="thingie-name-replicate"
      matInput
      type="text"
      [placeholder]="this._placeholder ? this._placeholder: ''"
      required />
  </mat-form-field>
</div> 

<div mat-dialog-actions [align]="'end'">
  <ng-container
    *ngFor="let button of _buttons"
  >
    <button
      *ngIf="button.matButtonType === 'stroked'"
      mat-stroked-button
      (click)="close(button)"
      [color]="button.color"
      [disabled]="button.isFormSubmitButton && _formControl?.invalid"
    >
      {{ button.text }}
    </button>
  
    <button
      *ngIf="button.matButtonType === 'flat'"
      mat-flat-button
      [color]="button.color"
      (click)="close(button)"
      [disabled]="button.isFormSubmitButton && _formControl?.invalid"
    >
      {{ button.text }}
    </button>
  </ng-container>
</div>
