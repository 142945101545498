import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BackendAvailabilityService } from 'src/app/services/backend-availability/backend-availability.service';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-backend-unavailabe-dialog',
  templateUrl: './backend-unavailabe-dialog.component.html',
  styleUrls: ['./backend-unavailabe-dialog.component.scss']
})
export class BackendUnavailabeDialogComponent implements OnInit, OnDestroy {

  private subscriptions = new SubSink();
  backendTimeout = false;
  timerSubscription?: Subscription;
  askForRefresh: boolean = false;

  constructor(
    private backendAvailability: BackendAvailabilityService,
    private dialogRef: MatDialogRef<BackendUnavailabeDialogComponent, any>,
  ) { }

  ngOnInit() {
    this.backendTimeout = false;
    this.startBackendTimeout();
  }

  ngOnDestroy() {
    if (this.timerSubscription !== undefined) {
      this.timerSubscription.unsubscribe();
    }
  }

  startBackendTimeout() {
    this.timerSubscription = interval(DefaultConfig.serviceStatusBar.backendTimeoutMilliseconds)
      .pipe(take(1))
      .subscribe(() => {
        this.backendTimeout = true;
      });
  }

  retry() {
    this.backendTimeout = false;
    this.timerSubscription?.unsubscribe();
    this.startBackendTimeout();
    this.backendAvailability.checkAvailability();
  }

  reload() {
    window.location.reload();
  }

  close() {
    this.dialogRef.close();
  }

}
