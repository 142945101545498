<div class="title-close-container-flex">
  <h1 mat-dialog-title>{{ prompt.title }}</h1>
  <div mat-dialog-close></div>
</div>

<div>
  <mat-form-field>
    <input
      class="form-field"
      matInput
      [formControl]="textControl"
      required
      (keyup.enter)="onFinish()" />
    <mat-error *ngIf="textControl.errors && (textControl.touched || textControl.dirty)">
      <span *ngIf="textControl.errors?.required">
        Text is required
      </span>
    </mat-error>
  </mat-form-field>

</div>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    color="accent"
    (click)="onCancel()">
    Cancel
  </button>
  
  <button
    mat-flat-button
    color="primary"
    (click)="onSubmit()"
    [disabled]="!textControl.valid">
    Submit
  </button>
</mat-dialog-actions>
