<div *ngIf="showBlockedPopout; else normalPopout" class="blocked-by-container">
  <div class="rowContainer">
    <h3>Currently used in</h3>
    <div class="title-close-container-flex">
      <div mat-dialog-close></div>
    </div>
  </div>
  <div *ngFor="let key of deviceObj?.blockedBy">
    <p
      (click)="showThingieDetail(key.thingie)">
      <a role="link">{{getThingieName(key.thingie)}}</a>
      <ion-icon class="open-icon" name="open-outline"></ion-icon>
    </p>
  </div>
</div>

<ng-template #normalPopout>
<div class="device-detail-container">
  <div class="rowTitle">
    <ion-item class="deviceTitle" lines="none">
      <div class="statusButton">
      <img *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED' && 
      deviceObj?.info?.InternalState?.values?.ready === 'Available'; else isWorking"
      class="middleAlign"
      [src]="'assets.icons.byConnectionState.Connected' | translate"/>

      <ng-template #isWorking>
        <img *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED' && 
          deviceObj?.info?.InternalState?.values?.ready === 'Unavailable'; else isDisconnected" 
          class="workingDeviceIcon middleAlign"
          [src]="'assets.icons.byConnectionState.Working' | translate"/>
      </ng-template>

      <ng-template #isDisconnected>
        <img *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'DISCONNECTED'; else isErrored" 
          class="middleAlign"
          [src]="'assets.icons.byConnectionState.NotConnected' | translate"/>
      </ng-template>

      <ng-template #isErrored>
        <img class="middleAlign"
        [src]="'assets.icons.byConnectionState.Error' | translate"/>
      </ng-template>
      </div>
      <ng-container *ngIf="isEditingName; else notEditingName">
        &nbsp;&nbsp;
        <form class="inLineElements ">
          <input
              [formControl]="newNameFormControl"
              matInput
              type="text"
              id="newName"
              placeholder="{{deviceObj?.name}}"
              required />
          <button
          class="action-button "
          type="submit"
          (click)="saveNameEdit(newNameFormControl.value)"
          mat-flat-button
          color="primary">
            <ion-icon  name="save-outline"></ion-icon><span >Save</span>
          </button>
        </form>
      </ng-container>
      <ng-template #notEditingName>
        <span class="noEditElement label">
            {{deviceObj?.name}} 
          <ion-button class="noEditElement" fill="clear" color="dark" size="small" (click)="toggleNameEdit()">
            <ion-icon (click)="toggleNameEdit()" name="pencil-outline"></ion-icon>
          </ion-button>
        </span>
          
      </ng-template> 
    </ion-item>
    <div class="space"></div>
    <div class="save-button-wrapper rowContainer" >
      <ion-button fill="clear" color="dark" size="small" 
      [mat-menu-trigger-for]="extendedConfig">
        <ion-icon name="settings-outline"></ion-icon>
      </ion-button>
      <mat-menu
          #extendedConfig
          class="menu-6c55eac2-2022-4979-a92d-a3127535e0cc"
          xPosition="before"
          >
          <button
          mat-menu-item
          class="actionSize"
          (click)="onResetStatus()">
            <span >
                Reset
            </span>         
          </button>
          <mat-divider> </mat-divider>
          <button
          mat-menu-item
          *ngIf="isAdmin"
          #matOption
          class="actionSize"
          (click)="toggleDevicesArchived()">
            <span>
              {{ isDeviceArchived === true ? 'Restore' : 'Archive' }} 
            </span>
          </button>
      </mat-menu>
    </div>
    <div class="title-close-container-flex">
      <div mat-dialog-close></div>
    </div>
  </div>
  <mat-divider> </mat-divider>
  <div class="rowContainer">
    <div class="columnContainer spaceRight leftColumn">
      <div>
        <ng-container *ngIf="deviceObj?.blockedBy">
          <small class="label">Currently used in</small>
          <div *ngIf="deviceObj?.blockedBy?.length === 0; else oneThingie">
            <p class="text">-</p>
          </div>
          <ng-template #oneThingie>
            <div *ngIf="deviceObj?.blockedBy?.length === 1; else multipleThingies">
              <div *ngFor="let key of deviceObj?.blockedBy">
                <p
                (click)="showThingieDetail(key.thingie)">
                <a role="link" class="text">{{getThingieName(key.thingie)}}</a>
                <ion-icon class="open-icon" name="open-outline"></ion-icon>
              </p>
              </div>
            </div>
          </ng-template>
          <ng-template #multipleThingies>
            <div>
              <a class="clickable text"
              (click)="showBlockedByPopout()">
                {{deviceObj?.blockedBy?.length}}&nbsp;objects
              </a>
            </div>
          </ng-template>
        </ng-container>
      </div>

      <div>
        <small class="label">Device Type</small>
          <p class="text">{{translateDeviceTypeToName(deviceObj?.deviceInputTypes?.[0])}}</p>
      </div>

      <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsDO' ||
                  deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPH' ||
                  deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPHDO'">
        <small class="label">PT100 status</small>
        <p *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus !== 'CONNECTED'; else PT100NotConnected" class="text">
          -
        </p>
        <ng-template #PT100NotConnected>
          <p *ngIf="deviceObj?.info?.DOTSModuleStatus?.values?.Pt100State | pureFnCall:containsTrue; else notConnected" class="text">
            Connected
          </p>
          <ng-template #notConnected>
            <p class="text">Not connected</p>
          </ng-template>
        </ng-template>
      </div>

      <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
        <div *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED'; else noPanID">
          <small class="label">PanID</small>
            <p class="text">{{connectedCoordinatorPanID}}</p>
        </div>
        <ng-template #noPanID>
          <small class="label">PanID</small>
            <p class="text">-</p>
        </ng-template>
      </div>

      <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisCoordinator'">
        <ng-container *ngIf="isEditingPanID; else notEditingPanID">
          &nbsp;&nbsp;
          <form class="inLineElements">
            <input
                [formControl]="newPanIDFormControl"
                matInput
                type="text"
                id="newPanID"
                placeholder="{{deviceObj.configuration['1'].values?.panID}}"
                required />
            <button
            class="inLineElements action-button"
            type="button"
            (click)="savePanID(newPanIDFormControl.value)"
            mat-flat-button
            color="primary">
              <ion-icon class="middleAlign" name="save-outline"></ion-icon><span class="middleAlign">&nbsp;Save</span>
            </button>
          </form>
        </ng-container>


        <ng-template #notEditingPanID>
          <span>
            <small class="label">PanID</small>
            <ion-button fill="clear" color="dark" size="small" (click)="togglePanIDEdit()">
              <ion-icon class="middleAlign" (click)="togglePanIDEdit()" name="pencil-outline"></ion-icon>
            </ion-button>
          </span>
            <p class="text">{{deviceObj.configuration['1'].values?.panID}}</p>
        </ng-template>
      </div>

      <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
        <small class="label">Power state</small>
        <p *ngIf="this.deviceObj?.info?.InternalState?.values?.connectionStatus !== 'CONNECTED'; else isPowerState" class="text">
          -
        </p>
        <ng-template #isPowerState>
          <p *ngIf="deviceObj?.info?.StatusAnswer?.values?.batteryStateIsCharging">
            <ion-icon
            class="battery-charging-icon"
            name="battery-charging-outline"></ion-icon>
            <ion-label class="text">Charging</ion-label>
          </p>
          <div *ngIf="!deviceObj?.info?.StatusAnswer?.values?.batteryStateIsCharging">
            <p *ngIf="getBatteryPower() === 'Full'; else checkHalf">
              <ion-icon
              class="battery-icon"
              name="battery-full-outline"></ion-icon>
              <ion-label class="text">{{deviceObj?.info?.StatusAnswer?.values?.batteryPercentage}}%</ion-label>
            </p>
            <ng-template #checkHalf>
              <p *ngIf="getBatteryPower() === 'Half'; else checkLow">
                <ion-icon
                class="battery-half-icon"
                name="battery-half-outline"></ion-icon>
                <ion-label class="text">{{deviceObj?.info?.StatusAnswer?.values?.batteryPercentage}}%</ion-label>
              </p>
            </ng-template>
            <ng-template #checkLow>
              <p *ngIf="getBatteryPower() === 'Low'">
                <ion-icon
                class="battery-dead-icon"
                name="battery-dead-outline"></ion-icon>
                <ion-label class="text">{{deviceObj?.info?.StatusAnswer?.values?.batteryPercentage}}%</ion-label>
              </p>
            </ng-template>
          </div>
        </ng-template>
      </div>

      <div>
        <small class="label">Connection state</small>
          <p class="text">{{connectionState}}</p>
      </div>

      <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
        <small class="label">Connection signal strength</small>
          <p *ngIf="getSignalStrengthQuality() === 'Excellent'; else checkGood" class="text">
            <img class="middleAlign connectionImage"
            [src]="'assets.icons.bySignalStrength.Excellent' | translate"/>
            {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
          </p>
          <ng-template #checkGood>
            <p *ngIf="getSignalStrengthQuality() === 'Good'; else checkOk" class="text">
              <img class="middleAlign connectionImage"
              [src]="'assets.icons.bySignalStrength.Great' | translate"/>
              {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
            </p>
          </ng-template>
          <ng-template #checkOk>
            <p *ngIf="getSignalStrengthQuality() === 'Ok'; else checkBad" class="text">
              <img class="middleAlign connectionImage"
              [src]="'assets.icons.bySignalStrength.Good' | translate"/>
              {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
            </p>
          </ng-template>
          <ng-template #checkBad>
            <p *ngIf="getSignalStrengthQuality() === 'Bad'; else noSignal" class="text">
              <img class="middleAlign connectionImage"
              [src]="'assets.icons.bySignalStrength.Bad' | translate"/>
              {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
            </p>
          </ng-template>
          <ng-template #noSignal>
            <p class="text">
              <img class="middleAlign connectionImage"
              [src]="'assets.icons.bySignalStrength.Poor' | translate"/>
              -
            </p>
          </ng-template>
      </div>

      <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive' ||
                  deviceObj?.deviceInputTypes?.[0] === 'AquilaCGQSensorPlate' ||
                  deviceObj?.deviceInputTypes?.[0] === 'AquilaBioRSensorPlate'">
        <small class="label">Current state</small>
        <p *ngIf="deviceObj?.info?.InternalState?.values?.ready" class="text">Available</p>
        <p *ngIf="!deviceObj?.info?.InternalState?.values?.ready" class="text">Unavailable</p>
      </div>
    </div>

    <div class="columnContainer leftBorder rightColumn">
      <div class="rowContainer">
        <div class="columnContainer">
          <div>
            <small class="label">Firmware version</small>
            <div *ngIf="this.deviceObj?.info?.InternalState?.values?.connectionStatus !== 'CONNECTED'; else firmwareConnected">
              <p class="text">-</p>
            </div>
            <ng-template #firmwareConnected>
              <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaBioRSensorPlate' ||
                deviceObj?.deviceInputTypes?.[0] === 'AquilaCGQSensorPlate' ||
                deviceObj?.deviceInputTypes?.[0] === 'AquilaCGQBaseStation'"
              >
                <p class="text">{{deviceObj?.info?.InternalState?.values?.firmware}}</p>
              </div>
              <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisCoordinator' ||
                        deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
                <p class="text">{{deviceObj?.info?.IdentAnswer?.values?.gitTag}}</p>
              </div>
              <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsDO' ||
                    deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPH' ||
                    deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPHDO' ||
                    deviceObj?.deviceInputTypes?.[0] === 'AquilaLora'">
                <p class="text">{{loraPHDOSoftwareVersion}}</p>
              </div>
          </ng-template>
          </div>
          <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
            <div *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED'; else noChannel">
              <small class="label">Wireless channel number</small>
                <p class="text">{{connectedCoordinatorChannel}}</p>
            </div>
            <ng-template #noChannel>
              <small class="label">Wireless channel number</small>
                <p class="text">-</p>
            </ng-template>
          </div>

          <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisCoordinator'">
            <small class="label">Wireless channel number</small>
            <p class="text">{{deviceObj.configuration['2'].values?.channel}}</p>
          </div>

          <div class="maxWidthNet">
            <small class="middleAlign label">Assigned to teams</small>
            <span [matTooltip]="'You can only see teams you are part of'">
              <ion-icon
                class="info-icon middleAlign"
                name="information-circle-outline"></ion-icon>
            </span>
            
            <div *ngIf="deviceObj?.teams?.length === 0">
              <p class="text">
                -
              </p>
            </div>

            <mat-chip-list class="chip-list">
              <mat-chip
                *ngFor="let team of deviceObj?.teams">
                  <span
                    (click)="showTeamDetail(team)">
                    {{getTeamName(team)}}
                  </span>
              </mat-chip>
            </mat-chip-list>
          </div>

          <div>
            <a (click)="showDeviceDetail(deviceObj._id)"
                class="clickable">
              <p class="smallFont">Open Debugging Information&nbsp;<ion-icon class="open-icon" name="open-outline"></ion-icon></p>
            </a>
          </div>
        
        </div>

        <div>
          <img class="illustration" [src]="'assets.illustrations.byDeviceType.' + deviceObj?.deviceInputTypes?.[0] | translate"/>
        </div>
      </div>

    </div>
  </div>
    <div class="inLineRight">

      <div *ngIf="deviceObj?.deviceInputTypes?.[0] !== 'AquilaDotsDO' &&
                    deviceObj?.deviceInputTypes?.[0] !== 'AquilaDotsPH' &&
                    deviceObj?.deviceInputTypes?.[0] !== 'AquilaDotsPHDO'; else batchCalibration"
                    class="save-button-wrapper rowContainer">
        <button
          class="main-button"
          mat-flat-button
          color="primary"
          (click)="onActionSelection(selectedAction)"
        >
          <span>
            {{selectedAction}}
          </span>

        </button>

        <button
          class="extension-button"
          mat-flat-button
          color="primary"
          [mat-menu-trigger-for]="extendedSaveMenu"
        >
          <ion-icon name="chevron-down-outline"></ion-icon>
        </button>
        <mat-menu
          #extendedSaveMenu
          class="menu-6c55eac2-2022-4979-a92d-a3127535e0cd"
          xPosition="before"
        >
          <mat-option
            *ngFor="let action of deviceActions"
            (click) = "onActionSelection(action); setSelectedAction(action)"
            [value]="action"
            class="actionSize">
            {{translateDeviceAction(action)}}
            <mat-divider></mat-divider>
          </mat-option>
        </mat-menu>
      </div>
      <ng-template #batchCalibration>
        <div>
          <button
            mat-raised-button
            color="primary"
            [routerLink]="['/dots-calibration']"
            matDialogClose>
            Batch Calibration
          </button>
        </div>
      </ng-template>
    </div>
</div>
</ng-template>
