import { Component } from '@angular/core';
import { BackendAvailabilityService } from './services/backend-availability/backend-availability.service';
import { LogService } from './services/loggers/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    log: LogService,
    backendAvailability: BackendAvailabilityService,
  ) {
    backendAvailability.startChecking();
    log.info('Browser Data: ' + navigator.userAgent);
  }

}
