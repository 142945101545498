<div class="title-close-container-flex">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-close></div>
</div>

<mat-dialog-content>
    <div>
      {{ message }}
    </div>

    <div
      *ngIf="details !== undefined"
      class="details"
    >
      <mat-expansion-panel class="error-alert-panel">
        <mat-expansion-panel-header>
          Details
        </mat-expansion-panel-header>

          <div (mouseenter)="hoverOver=true" (mouseleave)="hoverOver=false" class="details-content">
            <textarea #errorTextArea readonly class="sizedTextarea">{{ details }}</textarea>
            <button
              mat-icon-button
              *ngIf="hoverOver"
              (click)="copyErrorMessage()"
              class="action-button-container copyButton"
            >
              <ion-icon name="copy"></ion-icon>
            </button>
        </div>
      </mat-expansion-panel>
    </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="null">
    Close
  </button>
</div>
