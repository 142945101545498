import { NgModule } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { DefaultConfig } from 'src/assets/default.config';

export function initializeKeycloak(
  keycloak: KeycloakService
) {
  return () =>
    keycloak.init({
      config: DefaultConfig.keycloak,
      initOptions: {
        onLoad: 'login-required'
      }
    });
}

// Easy fix for the service not having
// a Keycloak instance initialized,
// which otherwise would result in a
// access-on-undefined-error
export const KeycloakServiceMock = {
  isUserInRole: (_: any) => true,
  login: (_: any) => true,
  isLoggedIn: (_: any) => true,
  logout: (_: any) => true,
  getToken: (_: any) => '',
  updateToken: (_: any) => Promise.resolve(),
  init: (_: any) => true,
  loadUserProfile: (_: any) => new Promise(() => undefined),
  getKeycloakInstance: (_: any) => ({ subject: 'xy' }),
  keycloakEvents$: new Subject(),
};

@NgModule({
  declarations: [],
  providers: [
    {
      provide: KeycloakService,
      useValue: KeycloakServiceMock
    }
  ]
})
export class KeycloakMockModule { }
