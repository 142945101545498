const base = `http://${window.location.host}/api`;
const baseWS = `ws://${window.location.host}/api`;
const baseKeycloak = `http://${window.location.host}`;
// const base = `http://ab-nucdebian-01.intern.aquila-biolabs.de/api`;
// const baseWS = `ws://ab-nucdebian-01.intern.aquila-biolabs.de/api`;
// const baseKeycloak = `http://ab-nucdebian-01.intern.aquila-biolabs.de`;
// const base = `http://localhost/api`;
// const baseWS = `ws://localhost/api`;
// const baseKeycloak = `http://ab-nucdebian-01.intern.aquila-biolabs.de`;

const baseDataprocessing = base + '/dataprocessing';
const baseDevices = base + '/devices';
const baseNotifications = base + '/notifications';
const baseLogManagement = base + '/logs';
const baseSearch = base + '/search';
const baseAuthManagement = base + '/auth';

const baseUserData = baseAuthManagement + '/data';
const baseUserManagement = baseAuthManagement + '/user';
const baseTeamManagement = baseAuthManagement + '/team';
const baseProjectManagement = baseAuthManagement + '/project';

export const DefaultConfig = {
  general: {
    userInputDefaultDebounce_Milliseconds: 300
  },
  menuBar: {
    clockUpdateTimerInterval_Milliseconds: 1000,
    search: {
      debounceMilliseconds: 140,
      minStringLength: 3,
      minimumContainerWidthPixels: 200,
      searchButtonClickDelay: 300
    }
  },
  chartView: {
    defaultChartTypeKey: 'Scatter',
    liveDataBufferingTimespan: 10000,
    layout: {
      margin: { l: 30, t: 30, b: 30, r: 30, pad: 0 }
    }
  },
  keycloak: {
    // We most likely want to change this for an actual deployment.
    // However, I am unsure how to properly do this. So for now, it
    // needs a manual edit.
    url: baseKeycloak + `/auth`,
    realm: 'coco',
    clientId: 'angular_frontend',
  },
  uris: {
    thingieList: baseDataprocessing + '/thingie/',
    recentThingieList: baseDataprocessing + '/thingie/mostRecent',
    thingie: baseDataprocessing + '/thingie/{thingieId}',
    thingieArchive: baseDataprocessing + '/thingie/{thingieId}/archived',
    thingieProcess: baseDataprocessing + '/thingie/{thingieId}/process/{processIdx}/{action}',
    thingieTask: baseDataprocessing + '/thingie/{thingieId}/process/{processIdx}/task/{taskIdx}/{action}',
    thingieData: baseDataprocessing + '/thingie/{thingieId}/processes/{processIdx}/tasks/{taskIdx}/outputs/{outputName}/dataQuery',
    thingieOfflineData: baseDataprocessing + '/thingie/{thingieId}/processes/{processIdx}/tasks/{taskIdx}/inputs/{inputName}/dataQuery',
    thingieNameResolution: baseDataprocessing + '/thingie/resolveNamesFromIds',
    thingieDatabaseImport: baseDataprocessing + '/databaseImport',
    thingieDataExport: baseDataprocessing + '/dataExport',
    deviceData: baseDataprocessing + '/device/{deviceAddress}/packet/{packetType}{additionalFilters}/dataQuery',
    taskOneTime: baseDataprocessing + '/evaluateTask',
    offlineData: baseDataprocessing + '/data/offline-data',
    offlineDataQuery: baseDataprocessing + '/data/offline-data/query',
    offlineDataItem: baseDataprocessing + '/data/offline-data/{id}',
    dataInventory: baseDataprocessing + '/device/{deviceAddress}/data_inventory',
    tsvImport: baseDataprocessing + '/data/import/tsv?source={deviceAddress}&type={packetType}&config={config}&property={property}',
    search: baseSearch + '',
    device: baseDevices + '/{deviceId}',
    createDevice: baseDevices + '/',
    deviceList: baseDevices + '/',
    deviceArchive: baseDevices + '/{deviceId}/archived',
    deviceActions: baseDevices + '/{deviceId}/actions',
    deviceActionExecution: baseDevices + '/{deviceId}/actions/{action}',
    deviceBase: baseDevices + '/',
    deviceDisablePart: baseDevices + '/{address}/disable',
    deviceEnablePart: baseDevices + '/{address}/enable',
    resetDevice: baseDevices + '/{address}/resetDevice',
    deviceForceUnblock: baseDevices + '/{deviceId}/forceUnblock/thingie/{thingieId}/process/{processIdx}/task/{taskIdx}',
    removeDeviceConfig: baseDevices + '/{deviceAddress}/configuration/{handleID}',
    metaBase: baseDataprocessing + '/meta/',
    deviceDryRun: baseDevices + '/{deviceId}?dryRun=true',
    deviceTemplateCoco: baseDataprocessing + '/meta/devicespecification/coco/{version}',
    applicationTemplateList: baseDataprocessing + '/meta/template/applicationTemplate',
    taskTemplateList: baseDataprocessing + '/meta/template/taskTemplate',
    calibrationTemplateList: baseDataprocessing + '/meta/template/calibrationFilePostProcessing',
    usersettingTemplates: baseDataprocessing + '/templates/usersetting',
    notificationList: baseNotifications + '/',
    notification: baseNotifications + '/{id}',
    notificationDismiss: baseNotifications + '/{id}/dismiss',
    remoteCocoVersion: 'https://apt.coco.aquila-biolabs.com/latest_version',
    submitData: baseDataprocessing + '/data',
    dataWebsocket: baseWS + '/dataprocessing/ws',
    deviceWebsocket: baseWS + '/devices/ws',
    logWebsocket: baseWS + '/logs/ws',
    notificationsWebsocket: baseWS + '/notifications/ws',
    version: {
      dataprocessing: baseDataprocessing + '/debug/version',
      devicemanagement: baseDevices + '/debug/version',
      logmanagement: baseLogManagement + '/debug/version',
      notification: baseNotifications + '/debug/version',
      search: baseSearch + '/debug/version'
    },
    logs: baseLogManagement + '/',
    logSearch: baseLogManagement + '/search',
    userdata: {
      getUserData: baseUserData + '/{type}',
      getThingieUserData: baseUserData + '/{type}/user/{userid}/?thingie={thingieid}',
      newUserData: baseUserData + '/{type}/user/{userid}',
      specificUserData: baseUserData + '/{type}/user/{userid}/{backendid}'
    },
    swaggerServiceBase: base
  },
  websocket: {
    heartbeatInterval: 25000
  },
  uiNotifications: {
    defaultToastDurationMilliseconds: 3500
  },
  autoOffset: {
    defaultRangeEndMinute: 60
  },
  swagger: {
    apiUri: '/debug/openapi.json'
  },
  serviceStatusBar: {
    intervalMilliseconds: 15000,
    backendTimeoutMilliseconds: 2 * 60 * 1000
  },
  userManagement: {
    user: baseUserManagement,
    forceLogout: baseUserManagement + '/{id}/force-logout',
    lock: baseUserManagement + '/{id}/lock',
    unlock: baseUserManagement + '/{id}/unlock',
    changePassword: baseUserManagement + '/{id}/password',
    addPinnedCard: baseUserManagement + '/current/pinnedCards/{thingieids}/add',
    removePinnedCard: baseUserManagement + '/current/pinnedCards/{thingieids}/remove'
  },
  teamManagement: {
    team: baseTeamManagement,
    member: baseTeamManagement + '/{id}/member/{userId}',
    leader: baseTeamManagement + '/{id}/leader/{userId}',
  },
  projectManagement: {
    project: baseProjectManagement,
    viewer: baseProjectManagement + '/{id}/viewer/{userId}',
    editor: baseProjectManagement + '/{id}/editor/{userId}',
    archive: baseProjectManagement + '/{id}/archive',
    restore: baseProjectManagement + '/{id}/restore'
  },
  thingie: {
    refreshIntervalSeconds: 60,
    shareReplayDelaySeconds: 5
  },
  device: {
    shareReplayDelaySeconds: 5
  }
};
