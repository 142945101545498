import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import * as FileSaver from 'file-saver';
import { combineLatest, from, interval, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { finalize, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { IDialogResult as DataExportDTO } from 'src/app/components/dialogs/object-export-dialog/object-export-dialog.component';
import { AnnotationSeries } from 'src/app/model/annotation-series';
import { IDataSeries } from 'src/app/model/data-series';
import { IAnnotation, IAnnotationAttachment } from 'src/app/model/thingie/annotation';
import { IThingie } from 'src/app/model/thingie/thingie';
import { deepCopy, deepCopySync } from 'src/app/utility/deep-copy';
import { deepFreeze } from 'src/app/utility/deep-freeze';
import {
  createDefaultAnnotationDataSeries,
  createDefaultArrayValuesConfiguration,
  createDefaultThingieSeries
} from 'src/app/utility/default-object-creator';
import { bufferWhileTabIsHidden, delayedShareReplay } from 'src/app/utility/rxjs';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { IDataEvent, IThingieFilter, WebsocketConnectionService } from '../../common/websocket-connection.service';
import { LogService } from '../../loggers/logger.service';
import { ThingieService } from '../thingie-service.service';

@Injectable({
  providedIn: 'root'
})
export class ThingieServiceImpl implements ThingieService, OnDestroy {
  private subscriptions = new SubSink();
  private websocketUri = DefaultConfig.uris.dataWebsocket;
  private thingieCache: Map<string, Observable<IThingie>> = new Map();
  private thingieRefreshRequest$ = new Subject<void>();
  newThingieNotification$ = this.websocketConnection.subscribeWithFilter(this.websocketUri, {
    filterType: 'ThingieCreationFilter'
  });
  private readonly refrehIntervalSeconds = DefaultConfig.thingie.refreshIntervalSeconds;
  private thingieRefresh$ = merge(
    interval(this.refrehIntervalSeconds * 1000),
    this.thingieRefreshRequest$,
    this.newThingieNotification$
  ).pipe(
    startWith(undefined),
    switchMap(() => this.httpClient.get<IThingie[]>(DefaultConfig.uris.thingieList)),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  private thingieObservableList$ = this.thingieRefresh$.pipe(
    map(thingies => thingies.map(thingie => this.getThingieById(thingie._id!, true, thingie)))
  );
  private thingieList$ = this.thingieObservableList$.pipe(
    switchMap(thingies$ => thingies$.length > 0 ? combineLatest(thingies$) : of([]))
  );

  constructor(
    private httpClient: HttpClient,
    private log: LogService,
    private websocketConnection: WebsocketConnectionService,
  )
  {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  refresh(): Observable<void> {
    this.log.debug('Explicit thingie refresh requested');
    this.thingieRefreshRequest$.next();
    return of(undefined);
  }

  setTaskTargetState(thingieId: string, processIdx: number, taskIdx: number, state: string): Promise<void> {
    const uri = DefaultConfig.uris.thingieTask
      .replace('{thingieId}', thingieId)
      .replace('{processIdx}', processIdx.toString())
      .replace('{taskIdx}', taskIdx.toString())
      .replace('{action}', `targetState/${state}`);
    return this.httpClient.post<void>(uri, {}).toPromise();
  }

  getThingieList(): Observable<IThingie[]> {
    return this.thingieList$.pipe(
      map(list => deepCopySync(list))
    );
  }

  getThingieObservables(): Observable<Observable<IThingie>[]> {
    return this.thingieObservableList$;
  }

  recentThingieList$$ = this.newThingieNotification$.pipe(
    startWith(undefined),
    // re-fetch recent thingies
    switchMap(() => this.httpClient.get<IThingie[]>(DefaultConfig.uris.recentThingieList)),
    map((thingies: IThingie[]) => thingies.map(thingie => this.getThingieById(thingie._id!, true, thingie)))
  );

  recentThingieList$ = this.recentThingieList$$.pipe(
    switchMap(thingies$ => thingies$.length > 0 ? combineLatest(thingies$) : of([]))
  );

  getRecentThingieList$$(): Observable<Observable<IThingie>[]> {
    return this.recentThingieList$$;
  }

  getRecentThingieList$(): Observable<IThingie[]> {
    return this.recentThingieList$;
  }

  getThingieById(thingieId: string, writable?: boolean, initial?: IThingie): Observable<IThingie> {
    if (thingieId.length === 0) {
      throw new Error('Thingie without an id requested');
    }
    let thingie$ = this.thingieCache.get(thingieId);
    if (thingie$ !== undefined) {
      if (writable === true) {
        // return a copy
        return thingie$
          .pipe(switchMap(thingie => from(deepCopy(thingie))));
      }

      // return the original object, which is a deepFrozen object that cannot be changed
      return thingie$;
    }

    const uri = DefaultConfig.uris.thingie.replace('{thingieId}', thingieId);
    const backendRequest$ = initial !== undefined ? of(initial) : this.httpClient.get<IThingie>(uri);
    const websocketUpdate$ = this.subscribeUpdates(thingieId)
      .pipe(
        map(dataEvent =>  dataEvent.data)
      );
    const thingieAndUpdates$: Observable<IThingie> = merge(backendRequest$, websocketUpdate$).pipe(
      finalize(() => this.thingieCache.delete(thingieId)),
      delayedShareReplay(DefaultConfig.thingie.shareReplayDelaySeconds * 1000, 1),
      map(thingie => deepFreeze(thingie)),
    );

    this.thingieCache.set(thingieId, thingieAndUpdates$);
    return thingieAndUpdates$.pipe(switchMap(thingie => from(deepCopy(thingie))));
  }

  getAvailableDataSeriesFromThingie(thingie: IThingie): IDataSeries[] {
    this.log.debug(`ThingieService::getAvailableDataSeriesFromThingie() - thingie w. id ${thingie._id}`);
    const seriesList: IDataSeries[] = [];

    thingie.processes.forEach((process, pIndex) => {
      if(process.tasks) {
        process.tasks.forEach((task, oIndex) => {
          Object.entries(task.outputs).forEach(([outputKey, output]) => {
            for (const propertyToPlot of ['value', 'values']) {
              if (output.items[propertyToPlot]?.visibility === 'hidden') {
                continue;
              }
              // For scatter plots, the y dimension is always the first dimension of the 'value' item
              const yDimension = output.items[propertyToPlot]?.valueInfo[0];
              if (!yDimension) {
                // This is most likely not suited for a scatterplot
                continue;
              }
              const zDimension = output.items[propertyToPlot]?.structureInfo[0];
              const newSeries  = createDefaultThingieSeries();
              if (zDimension !== undefined) {
                newSeries.valueConfig = createDefaultArrayValuesConfiguration();
              }
              if (newSeries.identification.type !== 'thingieSeries') {
                throw new Error('Impossible series identification type');
              }

              newSeries.identification.dimensionName    = output.name ?? outputKey;
              newSeries.identification.output           = outputKey;
              newSeries.identification.processIndex     = pIndex;
              newSeries.identification.taskIndex = oIndex;
              newSeries.identification.thingieId         = <string>thingie._id;

              if (newSeries.valueConfig.type === 'scatterValues' || newSeries.valueConfig.type === 'arrayValues') {
                newSeries.valueConfig.unit = yDimension.unit;
              }
              newSeries.description = yDimension.description;
              newSeries.dataItemDescription = output.items;

              seriesList.push(newSeries);
            }
          });
          Object.entries(task.inputs).forEach(([inputKey, input]) => {
            if (input.source !== 'offlinedata') {
              return;
            }
            if (input.item.visibility === 'hidden') {
              return;
            }
            // For scatter plots, the y dimension is always the first dimension of the 'value' item
            const yDimension = input.item.valueInfo[0];
            if (!yDimension) {
              // This is most likely not suited for a scatterplot
              return;
            }
            const zDimension = input.item.structureInfo[0];
            const newSeries  = createDefaultThingieSeries();
            if (zDimension !== undefined) {
              newSeries.valueConfig = createDefaultArrayValuesConfiguration();
            }
            if (newSeries.identification.type !== 'thingieSeries') {
              throw new Error('Impossible series identification type');
            }

            newSeries.identification.dimensionName    = input.name ?? inputKey;
            newSeries.identification.output           = inputKey;
            newSeries.identification.processIndex     = pIndex;
            newSeries.identification.taskIndex = oIndex;
            newSeries.identification.thingieId         = <string>thingie._id;
            newSeries.identification.offlineData       = true;

            if (newSeries.valueConfig.type === 'scatterValues' || newSeries.valueConfig.type === 'arrayValues') {
              newSeries.valueConfig.unit = yDimension.unit;
            }
            newSeries.description = yDimension.description;
            newSeries.dataItemDescription = { value: input.item };

            seriesList.push(newSeries);
          });
        });
      }
    });

    return seriesList;
  }

  getAnnotationSeriesFromThingie( thingie: IThingie):  AnnotationSeries[] {
    let annotationSerieAsSystem = createDefaultAnnotationDataSeries();
    let annotationSerieAsUser = createDefaultAnnotationDataSeries();

    if(thingie._id === undefined) {
      return [];
    }
    annotationSerieAsSystem.identification.thingieId = thingie._id;
    annotationSerieAsUser.identification.thingieId = thingie._id;

    // TODO: create better place to set dimension, unit and others for
    // series.valueConfig....

    annotationSerieAsUser.description = 'Annotation Series created as User';
    annotationSerieAsUser.identification.systemAnnotation = false;


    annotationSerieAsSystem.description = 'Annotation Series created by System';
    annotationSerieAsSystem.identification.systemAnnotation = true;

    return [annotationSerieAsUser, annotationSerieAsSystem];
  }

  async createThingie(thingie: IThingie): Promise<IThingie> {
    return await this.httpClient.post<IThingie>(
      DefaultConfig.uris.thingieList,
      thingie
    ).toPromise();
  }

  async createThingieTemplate(thingies: IThingie[]) {
    // todo
    thingies;
  }

  async updateThingie(thingie: IThingie): Promise<IThingie> {
    if (thingie._id === undefined) {
      throw new Error('Cannot update thingie without an id');
    }
    return await this.httpClient.post<IThingie>(
      DefaultConfig.uris.thingie.replace('{thingieId}', thingie._id),
      thingie
    ).toPromise();
  }

  async saveAnnotations(thingieId: string, annotation: IAnnotation): Promise<IThingie> {
    if (thingieId === undefined || annotation === undefined) {
      throw new Error('Cannot save annotation');
    }
    return await this.httpClient.post<IThingie>(
      DefaultConfig.uris.thingie.replace('{thingieId}', thingieId) + '/processes/0/annotations',
      annotation
    ).toPromise();
  }

  async editAnnotations(thingieId: string, annotationId: number, annotation: IAnnotation): Promise<IThingie> {
    if (thingieId === undefined || annotationId === undefined || annotation === undefined) {
      throw new Error('Cannot update annotation');
    }
    return await this.httpClient.post<IThingie>(
      DefaultConfig.uris.thingie.replace('{thingieId}', thingieId) + `/processes/0/annotations/${annotationId}`,
      annotation
    ).toPromise();
  }

  async deleteAnnotations(thingieId: string, annotationId: number): Promise<IThingie> {
    if (thingieId === undefined || annotationId === undefined) {
      throw new Error('Cannot delete annotation');
    }
    return await this.httpClient.delete<IThingie>(
      DefaultConfig.uris.thingie.replace('{thingieId}', thingieId) + `/processes/0/annotations/${annotationId}`
    ).toPromise();
  }

  archiveThingie(thingieId: string): Promise<void> {
    return this.httpClient.post<void>(
      DefaultConfig.uris.thingieArchive.replace('{thingieId}', thingieId), {}
    ).toPromise();
  }

  unarchiveThingie(thingieId: string): Promise<void> {
    return this.httpClient.delete<void>(
      DefaultConfig.uris.thingieArchive.replace('{thingieId}', thingieId), {}
    ).toPromise();
  }

  /**
   * Upload specified file to backend. Report on progress via the Observable
   * @param thingieId target thingie
   * @param annotationId target annotation
   * @param file file to upload
   */
  async uploadAttachment(thingieId: string, annotationId: number, file: File[]): Promise<IAnnotationAttachment[]> {
    if (thingieId === undefined || annotationId === undefined || file === undefined) {
      throw new Error('Cannot upload attachment');
    }

    let returnData: IAnnotationAttachment[] = [];
    const promises = file.map(async file => {
      const formData = new FormData();
      formData.append('file', file);

      const fileUploadResponse = await this.httpClient.post<IAnnotationAttachment>(
        DefaultConfig.uris.thingie.replace('{thingieId}', thingieId) +
        `/processes/0/annotations/${annotationId}/files`,
        formData
      ).toPromise();

      returnData.push(fileUploadResponse);
    });

    await Promise.all(promises);

    return returnData;
  }

  async executeProcessAction(action: string, thingieId: string, processIdx: number): Promise<IThingie> {
    return await this.httpClient.post<any>(
      DefaultConfig.uris.thingieProcess
        .replace('{thingieId}', thingieId)
        .replace('{processIdx}', processIdx.toString())
        .replace('{action}', action),
      {}
    ).toPromise();
  }

  async executeTaskAction(action: string, thingieId: string, processIdx: number, taskIdx: number): Promise<any> {
    return await this.httpClient.post<any>(
      DefaultConfig.uris.thingieTask
        .replace('{thingieId}', thingieId)
        .replace('{processIdx}', processIdx.toString())
        .replace('{taskIdx}', taskIdx.toString())
        .replace('{action}', action),
      {}
    ).toPromise();
  }

  async exportThingieData(data: DataExportDTO, abortSignal?: AbortSignal): Promise<{data: Blob | null; type: 'raw' | 'zip'}> {
    let acceptHeader = new HttpHeaders({
      Accept: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/json'
      ]
    });

    const request$ = this.httpClient.post(
      DefaultConfig.uris.thingieDataExport,
      data,
      { headers: acceptHeader, observe: 'response', responseType: 'blob' }
    );

    const res = (await this.startCancelableDownload(
      request$,
      abortSignal,
      true
    )) as {data: Blob | null; headers: HttpHeaders | null };

    if(
      res.headers
      && res.headers.has('Content-Type')
    ) {
      const type = res.headers.get('Content-Type');
      if(type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return {
          data: res.data,
          type: 'raw'
        };
      } else if(type === 'application/octet-stream') {
        return {
          data: res.data,
          type: 'zip'
        };
      }
    }

    return {
      data: null,
      type: 'raw'
    };
  }

  /**
   * Start a cancelable download
   *
   * @param url
   * @param payload
   * @param acceptHeader
   * @param abortSignal
   * @param returnHeaders
   * @returns Either `Promise<Blob | null>` when `returnHeaders` is not provided
   *    or `Promise<{data: Blob | null; headers: HttpHeaders | null}>` when `returnHeaders` is `true`.
   */
  private async startCancelableDownload(
    request$: Observable<HttpResponse<Blob>>,
    abortSignal?: AbortSignal,
    returnHeaders?: true
  ): Promise<(Blob | null) | { data: Blob | null; headers: HttpHeaders | null }> {
    // `null` as indicator for download-abortion
    const res = await new Promise<HttpResponse<Blob> | null>((resolve, reject) => {
      let sub: Subscription;

      // To be called if the abort signal is fired
      const abortListener = () => {
        sub.unsubscribe();
        resolve(null);
      };

      sub = request$.subscribe({
        error: (err: unknown) => {
          abortSignal?.removeEventListener('abort', abortListener);
          reject(err);
        },
        next: data =>  {
          abortSignal?.removeEventListener('abort', abortListener);
          resolve(data);
        }
      });

      if(abortSignal) {
        abortSignal.addEventListener('abort', abortListener);
      }
    });

    if(res !== null) {
      if (res.body === null) {
        throw new Error('No file was produced');
      }

      if(returnHeaders) {
        return {
          data: res.body,
          headers: res.headers
        };
      }

      return res.body;
    } else {
      if(returnHeaders) {
        return {
          data: null,
          headers: null
        };
      }

      return null;
    }
  }

  async downloadAnnotationAttachment(annotationAttachment: IAnnotationAttachment): Promise<void> {
    if (annotationAttachment === undefined) {
      throw new Error('Cannot download annotation');
    }
    // downloads file without extensions somehow eventhough filename with correct extension is provided
    FileSaver.saveAs(`${DefaultConfig.uris.thingie.split('/api')[0]}/uploads/${annotationAttachment.sha256Hash}`,
      annotationAttachment.originalFileName);
  }

  async imagePreview(): Promise<string> {
    return `${DefaultConfig.uris.thingie.split('/api')[0]}/uploads/`;
  }

  subscribeUpdates(thingieId?: string): Observable<IDataEvent<IThingie>> {
    const requestFilter: IThingieFilter = {
      filterType: 'ThingieFilter',
      _id: thingieId
    };

    return this.websocketConnection.subscribeWithFilter<IThingie>(this.websocketUri, requestFilter)
      .pipe(bufferWhileTabIsHidden());
  }

  async resolveNamesFromIds(ids: string[]): Promise<{ [id: string]: string }> {
    if(!ids.length) {
      return {};
    }

    return this.httpClient.post<{ [id: string]: string }>(
      DefaultConfig.uris.thingieNameResolution,
      {
        idList: ids
      }
    ).toPromise();
  }

  getVersion(): Observable<string> {
    return this.httpClient.get(
      DefaultConfig.uris.version.dataprocessing,
      {
        responseType: 'text'
      }
    );
  }

  /**
   * Upload specified file to backend. Report on progress via the Observable
   * @param thingieId target thingie
   * @param annotationId target annotation
   * @param file file to upload
   */
  async importThingie(files: File[], replacementProjectId: string|undefined, replacementTeamId: string|undefined): Promise<any> {
    if (files === undefined) {
      throw new Error('No files selected');
    }

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      if (replacementProjectId) {
        formData.append('replacementProjectId', replacementProjectId);
      }
      if (replacementTeamId) {
        formData.append('replacementTeamId', replacementTeamId);
      }

      await this.httpClient.post<any>(
        DefaultConfig.uris.thingieDatabaseImport,
        formData
      ).toPromise();
    }
  }

}
