import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface ISnackbarDefaultContentContainerOptions {
  message: string;
  action?: {
    text: string;
    actionsToExecute: { (): void }[];
  };
}

@Component({
  selector: 'app-snackbar-default-content-container',
  templateUrl: './snackbar-default-content-container.component.html',
  styleUrls: ['./snackbar-default-content-container.component.scss']
})
export class SnackbarDefaultContentContainerComponent {
  private actions: { (): void }[] = [];

  action?: string;
  message: string;

  isMultiwordAction = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: ISnackbarDefaultContentContainerOptions,
    private theSnack: MatSnackBarRef<SnackbarDefaultContentContainerComponent>
  ) {
    if(!data) {
      throw new Error('Options not provided');
    }

    this.message = data.message;

    if(data.action) {
      this.action  = data.action.text;
      this.actions = data.action.actionsToExecute;

      if(this.action.split(/\s+/).length > 1) {
        this.isMultiwordAction = true;
      }
    }
  }

  onAction() {
    for(const action of this.actions) {
      action();
    }

    this.theSnack.dismissWithAction();
  }

}
