import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import {
  DialogService as ConfigurableDialog,
  IDialogParams as ConfigurableDialogOptions
} from 'src/app/components/dialogs/configurable-dialog/configurable-dialog.component';
import { PromptDialogComponent } from 'src/app/components/dialogs/prompt/prompt-dialog.component.';
import { ErrorAlertComponent } from 'src/app/components/error-alert-modal/error-alert.component';
import {
  ISnackbarDefaultContentContainerOptions,
  SnackbarDefaultContentContainerComponent
} from 'src/app/components/snackbar-default-content-container/snackbar-default-content-container.component';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { BackendAvailabilityService } from '../../backend-availability/backend-availability.service';
import { LogService } from '../../loggers/logger.service';
import { ConfirmationResults, IErrorAlertOptions, ISnackOptions, UINotificationService } from '../uinotification.service';


export interface ConfirmationDialogOptions {
  title?: string;
  message: string;
  approvalButton?: {
    text?: string;
    color?: 'primary' | 'alert' | 'warn';
  };
  rejectionButton?: {
    text?: string;
    color?: 'primary' | 'alert' | 'warn';
  };
}

@Injectable()
export class UINotificationServiceImpl implements UINotificationService {

  msgs = new Subject<{ opts: IErrorAlertOptions; msg: { logMessage: string; error: any } }>();
  subscriptions = new SubSink();

  constructor(
    private configurableDialog: ConfigurableDialog,
    private modalCtrl: MatDialog,
    private snackBar: MatSnackBar,
    private logService: LogService,
    private backendAvailability: BackendAvailabilityService
  )
  {
    this.subscriptions.sink = this.msgs
      // deduplicate based on log message
      .pipe(
        tap(() => this.backendAvailability.didErrorsHappen = true),
        distinctUntilChanged((el1, el2) => el1.msg.logMessage === el2.msg.logMessage),
        tap(() => this.backendAvailability.checkAvailability()),
        mergeMap((msg) => this.backendAvailability.status$.pipe(
          take(1),
          filter(status => status.anyUnavailable === false),
          map(() => msg)
        ))
      )
      .subscribe(({ opts, msg }) => {
        const { logMessage, error } = msg;
        this.logService.error(`Uncaught exception - ${logMessage}`, error);

        this.modalCtrl.open(
          ErrorAlertComponent,
          {
            data: {
              ...opts,
              details: new Date().toLocaleString() + ': ' + logMessage
            },
            panelClass: 'error-alert-dialog-panel'
          }
        );
      });
  }

  async askForConfirmation(opts: string | ConfirmationDialogOptions): Promise<ConfirmationResults> {
    let arg: ConfigurableDialogOptions = {
      message: '',
    };

    if(typeof opts === 'string') {
      arg.message = opts;
    } else {
      arg = {
        message: opts.message,
        title: opts.title,
        buttons: [{
          text: opts.rejectionButton?.text ?? 'Reject',
          color: opts.rejectionButton?.color,
          dialogResult: null,
          matButtonType: 'stroked'
        }, {

          text: opts.approvalButton?.text ?? 'Confirm',
          color: opts.approvalButton?.color,
          dialogResult: true,
          matButtonType: 'flat'
        }]
      };

    }

    const result = await this
      .configurableDialog
      .open(arg, { maxWidth: 630 })
      .afterClosed()
      .toPromise();


    if(result === true) {
      return ConfirmationResults.Yes;
    }

    return ConfirmationResults.No;
  }

  async showMessageDialog(opts: ConfigurableDialogOptions) {

    const result = await this
      .configurableDialog
      .open(opts, { maxWidth: 630 })
      .afterClosed()
      .toPromise();
    return result as any;
  }

  async displaySnackbar(opts: ISnackOptions): Promise<void> {
    // Async, because ionic toasts (will be used in future) run asynchronous

    if(!opts.message) {
      throw new Error(`Missing property 'message'; message is required.`);
    }

    const defaultDuration = DefaultConfig
      .uiNotifications
      .defaultToastDurationMilliseconds;

    const data: ISnackbarDefaultContentContainerOptions = {
      message: opts.message,
      action: opts.action
    };

    this.snackBar.openFromComponent(
      SnackbarDefaultContentContainerComponent,
      {
        data,
        duration: opts.durationMilliseconds ?? defaultDuration,
      }
    );
  }

  async parseErrorToLogMessage(error: any): Promise<any> {
    let logMessage: string|undefined = undefined;

    let errorResponse: HttpErrorResponse | undefined = undefined;
    if (error instanceof Error && (error as any).rejection instanceof HttpErrorResponse) {
      errorResponse = (error as any).rejection;
    } else if(error instanceof HttpErrorResponse) {
      errorResponse = error;
    }

    if (errorResponse !== undefined) {
      var detailError = '';
      if (errorResponse.error !== null && errorResponse.error !== undefined) {
        let errorText;
        if (errorResponse.error instanceof Blob) {
          errorText = await errorResponse.error.text();
        } else {
          errorText = JSON.stringify(errorResponse.error);
        }
        detailError = ' ' + errorText;
      }
      logMessage = JSON.stringify(errorResponse) + detailError;
    }
    if(error instanceof Error) {
      logMessage += error.stack ?? error.toString();
    }
    if (logMessage === undefined && error !== undefined) {
      logMessage = JSON.stringify(error);
    }
    if (logMessage !== undefined) {
      error = undefined;
    }
    return { logMessage, error };
  }

  async displayErrorAlert(opts: IErrorAlertOptions, error: any | undefined = undefined): Promise<void> {
    let msg = await this.parseErrorToLogMessage(error);
    this.msgs.next(({ opts, msg }));
  }

  async prompt(prompt: string, _default?: string): Promise<string | undefined> {
    return new Promise((resolve) => {
      const dialogRef = this.modalCtrl.open(
        PromptDialogComponent,
        {
          data: { title: prompt, _default },
          panelClass: 'prompt-dialog-panel'
        }
      );

      const sub = dialogRef.afterClosed().subscribe((next: string | undefined) => {
        sub.unsubscribe();
        resolve(next);
      });
    });
  }
}
