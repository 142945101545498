import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AccordionGroupComponent } from './accordion-group/accordion-group.component';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';

@NgModule({
  declarations: [
    AccordionGroupComponent,
    AccordionItemComponent
  ],
  imports: [
    IonicModule
  ],
  exports: [
    AccordionGroupComponent,
    AccordionItemComponent
  ]
})
export class AccordionModule {}
