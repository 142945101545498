import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDialogResult as DataExportDTO } from 'src/app/components/dialogs/object-export-dialog/object-export-dialog.component';
import { AnnotationSeries } from 'src/app/model/annotation-series';
import { IDataSeries } from 'src/app/model/data-series';
import { IAnnotation, IAnnotationAttachment } from 'src/app/model/thingie/annotation';
import { IThingie } from 'src/app/model/thingie/thingie';
import { ThingieServiceImpl } from './implementation/thingie-service.service';

@Injectable({
  providedIn: 'root',
  useClass: ThingieServiceImpl
})
export abstract class ThingieService {
  abstract setTaskTargetState(thingieId: string, processIdx: number, taskIdx: number, state: string): Promise<void>;

  /**
   * Out-of-band-refresh the internal thingie list.
   *
   * Pushes the thingie updates to subscribers.
   *
   * @return Observable which completes once the refresh is done.
   */
  abstract refresh(): Observable<void>;

  abstract getThingieList(): Observable<IThingie[]>;

  abstract getThingieObservables(): Observable<Observable<IThingie>[]>;

  abstract getRecentThingieList$$(): Observable<Observable<IThingie>[]>;

  abstract getRecentThingieList$(): Observable<IThingie[]>;

  /**
   * Acquire thingie/object
   *
   * @param thingieId
   * @param writableCopy If `true`, returns a writeable copy.
   *  If `false` or `undefined`, returns a frozen instance, which is faster.
   * @returns Observable which returns the requested thingie
   *  on each subscription
   */
  abstract getThingieById(thingieId: string, writableCopy?: boolean): Observable<IThingie>;

  abstract getAvailableDataSeriesFromThingie(thingie: IThingie): IDataSeries[];

  abstract getAnnotationSeriesFromThingie(thingie: IThingie): AnnotationSeries[];

  abstract createThingie(thingie: IThingie): Promise<IThingie>;

  abstract createThingieTemplate(thingies: IThingie[]): any;

  abstract updateThingie(thingie: IThingie): Promise<IThingie>;

  abstract executeProcessAction(action: string, thingieId: string, processIdx: number): Promise<IThingie>;

  abstract executeTaskAction(action: string, thingieId: string, processIdx: number, taskIdx: number): Promise<any>;

  abstract exportThingieData(
    data: DataExportDTO,
    abortSignal?: AbortSignal
  ): Promise<{data: Blob | null; type: 'raw' | 'zip'}>;

  abstract saveAnnotations(thingieId: string, annotation: IAnnotation): Promise<IThingie>;

  abstract editAnnotations(thingieId: string, annotationId: number, annotation: IAnnotation): Promise<IThingie>;

  abstract uploadAttachment(thingieId: string, annotationId: number, file: File[]): Promise<IAnnotationAttachment[]>;

  abstract downloadAnnotationAttachment(annotationAttachment: IAnnotationAttachment): Promise<void>;

  abstract deleteAnnotations(thingieId: string, annotationId: number): Promise<IThingie>;

  abstract archiveThingie(thingieId: string): Promise<void>;

  abstract unarchiveThingie(thingieId: string): Promise<void>;

  abstract imagePreview(): Promise<string>;

  abstract resolveNamesFromIds(ids: string[]): Promise<{ [id: string]: string }>;

  abstract getVersion(): Observable<string>;

  abstract importThingie(file: File[], replacementProjectId: string|undefined, replacementTeamId: string|undefined): Promise<any>;
}
