import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MaterialImportsModule } from 'src/app/material-imports.module';
import { CustomPipesModule } from 'src/app/utility/pipes/custom-pipes.module';
import { AccordionModule } from '../accordion/accordion.module';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { MenuBarComponent } from './menu-bar.component';


@NgModule({
  declarations: [ MenuBarComponent ],
  imports: [
    CommonModule,
    MaterialImportsModule,
    SkeletonLoaderModule,
    RouterModule,
    AccordionModule,
    CustomPipesModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [ MenuBarComponent ]
})
export class MenuBarModule { }
