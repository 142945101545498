import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pureFnCall',
  pure: true
})
export class PureFunctionCallPipe implements PipeTransform {

  transform<T>(value: unknown, memberFunction: (...args: any[]) => T, ...args: unknown[]): T {
    args.unshift(value);

    return memberFunction(...args);
  }

}
